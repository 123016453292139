import {
  ForgotPassword,
  Login,
  Logout,
  Registration,
  ConfirmPassword,
} from "src/screens/guest";

const guest = [
  {
    path: "/",
    exact: true,
    component: Login,
  },
  {
    path: "login",
    exact: true,
    component: Login,
  },
  {
    path: "registration",
    exact: true,
    component: Registration,
  },
  {
    path: "forgot-password",
    exact: true,
    component: ForgotPassword,
  },
  {
    path: "confirm-forgot-password/:token",
    exact: true,
    component: ConfirmPassword,
  },
  {
    path: "logout",
    exact: true,
    component: Logout,
  },
];

export default guest;
