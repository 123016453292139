export const tipeInputKuisioner = {
  pilihan: 1,
  pilihan_multi: 2,
  kondisi: 3,
  isian_teks: 4,
  isian_bilangan: 5,
  isian_tanggal: 6,
};

export const tipeInputSurvey = {
  string: "string",
  double: "double",
  integer: "integer",
};

export const tipeComboWilDynamic = {
  ref_rt: "RT",
  ref_rw: "RW",
  ref_desa: "DESA",
  ref_kecamatan: "KECAMATAN",
};
