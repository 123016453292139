import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "src/components/molecules";
import {
  actGetJenisSurveys,
  actAddJenisSurvey,
  actSetAktifSurvey,
  actSetTidakAktifSurvey,
  actGetJenisSurveyById,
  actGetRefJenisSurveys,
} from "src/redux/actions";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Tooltip, Combobox } from "src/components/atoms";

const SectionTable = () => {
  const history = useHistory();
  const { dataJenisSurveys, dataRefJenisSurveys } = useSelector(
    (state) => state.refReducer
  );
  const {
    formState: { errors },
    control,
  } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actGetJenisSurveys());
    dispatch(actGetRefJenisSurveys());
  }, [dispatch]);

  const btnAdd = (id) => dispatch(actAddJenisSurvey(id));
  const btnEdit = (id) => dispatch(actGetJenisSurveyById(id, history));
  const btnActivate = (id) => dispatch(actSetAktifSurvey(id));
  const btnDeactivate = (id) => dispatch(actSetTidakAktifSurvey(id));

  const jsonColumn = [
    {
      name: "Id",
      header: "AKSI",
      selector: (row) => (
        <>
          <button
            onClick={() => btnEdit(row.getValue())}
            className="btn btn-sm btn-info mr-1"
          >
            <i className="fa fa-pencil mr-1" /> Edit Survey
          </button>
        </>
      ),
    },
    {
      name: "Nama",
      header: "Nama",
    },
    {
      name: "Tahun",
      header: "Tahun",
    },
    {
      name: "Status",
      header: "STATUS",
      selector: ({ row }) =>
        row.original.Status === "Aktif" ? (
          <Tooltip
            position="top"
            id="tooltip-top"
            desc={
              <span>
                status survey ini <b>aktif</b>, anda dapat menonaktifkan dengan
                klik tombol nya
              </span>
            }
          >
            <span
              onClick={() => {
                btnDeactivate(row.original.Id);
              }}
              className="badge badge-primary cursor-pointer"
            >
              Aktif
            </span>
          </Tooltip>
        ) : (
          <Tooltip
            position="top"
            id="tooltip-top"
            desc={
              <span>
                status survey ini <b>tidak aktif</b>, anda dapat mengaktifkan
                dengan klik tombol nya
              </span>
            }
          >
            <span
              onClick={() => {
                btnActivate(row.original.Id);
              }}
              className="badge badge-danger cursor-pointer"
            >
              Non-Aktif
            </span>
          </Tooltip>
        ),
    },
    {
      name: "Tanggalmulai",
      header: "Tanggalmulai",
    },
    {
      name: "Tanggalselesai",
      header: "Tanggalselesai",
    },
  ];

  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12">
        <div className="card planned_task">
          <div className="header">
            <h2>Tabel Data</h2>
          </div>

          <div className="body">
            <div className="flex flex-col">
              <div className="col-md-10 d-flex">
                {dataRefJenisSurveys && (
                  <Controller
                    control={control}
                    name="surveys"
                    render={({ field, value }) => (
                      <Combobox
                        label={"Generate Survey"}
                        options={dataRefJenisSurveys}
                        values={value}
                        isError={errors.surveys}
                        onChange={(val) => {
                          field.onChange(val);
                          btnAdd(val[0].value);
                        }}
                      />
                    )}
                  />
                )}
              </div>
            </div>
            <DataTable data={dataJenisSurveys} column={jsonColumn} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTable;
