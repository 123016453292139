/* eslint-disable array-callback-return */
import { AuthService } from "src/services";
import { loadingBar } from "src/utils";
import Swal from "sweetalert2/dist/sweetalert2";

export const actFormLogin = (formType, formValue) => {
  return { type: "FORM_LOGIN", formType, formValue };
};
export const actFormForgetPassword = (formType, formValue) => {
  return { type: "FORM_FORGET_PASSWORD", formType, formValue };
};

export const actLogin = (iData) => {
  return (dispatch) => {
    if (!iData.usernameOrEmail) {
      Swal.fire("Gagal", `username atau email harus diisi!`, "error");
      return;
    }
    if (!iData.password) {
      Swal.fire("Gagal", `password harus diisi!`, "error");
      return;
    }
    loadingBar(true);
    var fd = new FormData();
    fd.append("usernameOrEmail", iData.usernameOrEmail);
    fd.append("password", iData.password);
    AuthService.login(fd).then((res) => {
      var data = res.data;
      if (data.IsSuccess) {
        localStorage.setItem("TOKEN", data.Data.Token);
        localStorage.setItem("REFRESH_TOKEN", data.Data.RefreshToken);
        Swal.fire("Berhasil", `Selamat datang...`, "success").then(
          () => (window.location = "/dashboard")
        );
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const actRegister = (iData) => {
  return () => {
    loadingBar(true);
    var fd = new FormData();
    fd.append("username", iData.username);
    fd.append("email", iData.email);
    fd.append("firstName", iData.firstName);
    fd.append("middleName", iData.middleName);
    fd.append("lastName", iData.lastName || "");
    fd.append("address", iData.address);
    if (iData.files)
      iData.files?.map((item, index) => {
        if (item) {
          fd.append(`files[${index}].Type`, item.type);
          fd.append(`files[${index}].Title`, item.title);
          fd.append(`files[${index}].FileUpload`, item.fileUpload);
        }
      });
    AuthService.register(fd).then((res) => {
      loadingBar(false);
      var data = res.data;
      if (data.IsSuccess) {
        Swal.fire("Berhasil", "Akun berhasil didaftarkan", "success").then(
          () => (window.location = "/")
        );
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const actForgotPassword = (iData) => {
  return () => {
    loadingBar(true);
    var fd = new FormData();
    fd.append("email", iData.email);
    AuthService.forgotPassword(fd).then((res) => {
      loadingBar(false);
      var data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          "Silakan cek email anda untuk instruksi selanjutnya...",
          "success"
        ).then(() => (window.location = "/login"));
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const actForgotPasswordConfirm = (token) => {
  return (dispatch) => {
    loadingBar(true);
    AuthService.forgotPasswordConfirm(token).then((res) => {
      loadingBar(false);
      var data = res.data;
      if (data.IsSuccess) {
        dispatch(actFormForgetPassword("msgConfirmPass", "success"));
      } else {
        dispatch(actFormForgetPassword("msgConfirmPass", "error"));
      }
    });
  };
};
