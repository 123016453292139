const initialState = {
  dataRefProvinsis: [],
  dataRefKabupatens: [],
  dataRefKecamatans: [],
  dataRefDesas: [],
  dataRefRws: [],
  dataRefRts: [],
  dataRefJenisSurveys: [],
  dataRefJenisKuisioners: [],
  dataJenisSurveys: [],
  dataJenisSurveyById: null,
  dataRefJenisFileSurveys: [],
};

const refReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_REF_PROVINSIS":
      return { ...state, dataRefProvinsis: action.payload };
    case "DATA_REF_KABUPATENS":
      return { ...state, dataRefKabupatens: action.payload };
    case "DATA_REF_KECAMATANS":
      return { ...state, dataRefKecamatans: action.payload };
    case "DATA_REF_DESAS":
      return { ...state, dataRefDesas: action.payload };
    case "DATA_REF_RWS":
      return { ...state, dataRefRws: action.payload };
    case "DATA_REF_RTS":
      return { ...state, dataRefRts: action.payload };
    case "DATA_REF_JENIS_SURVEYS":
      return { ...state, dataRefJenisSurveys: action.payload };
    case "DATA_REF_JENIS_KUISIONERS":
      return { ...state, dataRefJenisKuisioners: action.payload };
    case "DATA_JENIS_SURVEYS":
      return { ...state, dataJenisSurveys: action.payload };
    case "DATA_JENIS_SURVEY_BY_ID":
      return { ...state, dataJenisSurveyById: action.payload };
    case "DATA_JENIS_FILE_SURVEYS":
      return { ...state, dataRefJenisFileSurveys: action.payload };
    default:
      return state;
  }
};

export default refReducer;
