import { instanceAuth } from "src/utils";

class WilService {
  getProvinsis(data) {
    return instanceAuth.get("/Wil/getProvinsis", data);
  }
  getKabupatens(provinsiId) {
    return instanceAuth.get(`/Wil/getKabupatens?provinsiId=${provinsiId}`);
  }
  getKecamatans(kabupatenId) {
    return instanceAuth.get(`/Wil/getKecamatans?kabupatenId=${kabupatenId}`);
  }
  getDesas(kecamatanId) {
    return instanceAuth.get(`/Wil/getDesas?kecamatanId=${kecamatanId}`);
  }
  getRws(desaId) {
    return instanceAuth.get(`/Wil/getRws?desaId=${desaId}`);
  }
  getRts(rwId) {
    return instanceAuth.get(`/Wil/getRts?rwId=${rwId}`);
  }

  getWilReferences() {
    return instanceAuth.get("/Wil/getWilReferences");
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new WilService();
