const initialState = {
  dataGetMap: null,
  dataGetCharts: [],
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_GET_MAP":
      return { ...state, dataGetMap: action.payload };
    case "DATA_GET_CHARTS":
      return { ...state, dataGetCharts: action.payload };
    default:
      return state;
  }
};

export default homeReducer;
