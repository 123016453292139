import { instanceAuth } from "src/utils";

class MapService {
  getLayerGroups() {
    return instanceAuth.get("/map/GetLayerGroups");
  }
  getLayerGroup(id) {
    return instanceAuth.get(`/map/GetLayerGroup?id=${id}`);
  }
  addLayerGroup(data) {
    return instanceAuth.post("/map/AddLayerGroup", data);
  }
  editLayerGroup(data) {
    return instanceAuth.post("/map/EditLayerGroup", data);
  }
  deleteLayerGroup(data) {
    return instanceAuth.post(`/map/DeleteLayerGroup`, data);
  }

  getLayers(groupId) {
    return instanceAuth.get(`/map/GetLayers?groupId=${groupId}`);
  }
  getLayer(id) {
    return instanceAuth.get(`/map/GetLayer?id=${id}`);
  }
  addLayer(data) {
    return instanceAuth.post("/map/AddLayer", data);
  }
  editLayer(data) {
    return instanceAuth.post("/map/EditLayer", data);
  }
  deleteLayer(data) {
    return instanceAuth.post(`/map/DeleteLayer`, data);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new MapService();
