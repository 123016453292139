import React from "react";
import { LayoutClient } from "src/components/molecules";
import SectionContent from "./parts/SectionContent";

const Map = () => {
  return (
    <LayoutClient page="Map" breadcrumb={[{ name: "Page", navigate: "" }]}>
      <SectionContent />
    </LayoutClient>
  );
};

export default Map;
