const initialState = {
  formSdiSurvey: {
    judul: "",
    definisi: "",
  },
  dataSdiJenisSurveys: [],
  dataSdiJenisKuisioners: [],
  dataSdiPublicationDatasetTypes: [],
  dataSdiLicenses: [],
  dataSdiScopes: [],
  dataSdiMasterTables: [],
  dataSdiFrequencies: [],
  dataSdiPeriod: [],
  dataSdiTags: [],
  dataSdiAgencies: [],
  dataSdiPublicationDocumentTypes: [],
  dataSdiTopics: [],
  dataSdiCategories: [],
  dataSdiDatasetTypes: [],
  dataSdiFieldTypes: [],
  dataSdiYears: [],

  dataSdiAttrTemplateSurvey: [],
  dataSdiAttrTemplateKuisioner: [],
};

const sdiReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FORM_SDI_SURVEY":
      return {
        ...state,
        formSdiSurvey: {
          ...state.formSdiSurvey,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_SDI_SURVEY":
      return {
        ...state,
        formSdiSurvey: initialState.formSdiSurvey,
      };
    case "DATA_SDI_JENIS_SURVEYS":
      return { ...state, dataSdiJenisSurveys: action.payload };
    case "DATA_SDI_JENIS_KUISIONERS":
      return { ...state, dataSdiJenisKuisioners: action.payload };
    case "DATA_SDI_PUBLICATION_DATASET_TYPES":
      return { ...state, dataSdiPublicationDatasetTypes: action.payload };
    case "DATA_SDI_LICENSES":
      return { ...state, dataSdiLicenses: action.payload };
    case "DATA_SDI_SCOPES":
      return { ...state, dataSdiScopes: action.payload };
    case "DATA_SDI_MASTER_TABLES":
      return { ...state, dataSdiMasterTables: action.payload };
    case "DATA_SDI_FREQUENCIES":
      return { ...state, dataSdiFrequencies: action.payload };
    case "DATA_SDI_PERIOD":
      return { ...state, dataSdiPeriod: action.payload };
    case "DATA_SDI_TAGS":
      return { ...state, dataSdiTags: action.payload };
    case "DATA_SDI_AGENCIES":
      return { ...state, dataSdiAgencies: action.payload };
    case "DATA_SDI_PUBLICATION_DOCUMENT_TYPES":
      return { ...state, dataSdiPublicationDocumentTypes: action.payload };
    case "DATA_SDI_TOPICS":
      return { ...state, dataSdiTopics: action.payload };
    case "DATA_SDI_CATEGORIES":
      return { ...state, dataSdiCategories: action.payload };
    case "DATA_SDI_DATASET_TYPES":
      return { ...state, dataSdiDatasetTypes: action.payload };
    case "DATA_SDI_FIELD_TYPES":
      return { ...state, dataSdiFieldTypes: action.payload };
    case "DATA_SDI_YEARS":
      return { ...state, dataSdiYears: action.payload };
    case "DATA_SDI_ATTR_TEMPLATE_SURVEY":
      return { ...state, dataSdiAttrTemplateSurvey: action.payload };
    case "DATA_SDI_ATTR_TEMPLATE_KUISIONER":
      return { ...state, dataSdiAttrTemplateKuisioner: action.payload };
    default:
      return state;
  }
};

export default sdiReducer;
