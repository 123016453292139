import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "src/components/molecules";
import {
  actDeleteLayerGroup,
  actGetLayerGroup,
  actGetLayerGroups,
  actGetLayers,
} from "src/redux/actions";
import { useHistory } from "react-router-dom";

const SectionTable = () => {
  const history = useHistory();
  const dataMapLayerGroups = useSelector(
    (state) => state.mapReducer.dataMapLayerGroups
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actGetLayerGroups());
  }, [dispatch]);

  const btnEdit = (id) => dispatch(actGetLayerGroup(id, history));
  const btnDel = (id) => dispatch(actDeleteLayerGroup(id));
  const btnPreview = (id) => dispatch(actGetLayers(id, history));

  const jsonColumn = [
    {
      name: "Id",
      header: "AKSI",
      selector: (row) => (
        <>
          <button
            onClick={() => btnPreview(row.getValue())}
            className="btn btn-sm btn-warning mr-1"
          >
            <i className="fa fa-map mr-1" />
            Layer
          </button>
          <button
            onClick={() => btnEdit(row.getValue())}
            className="btn btn-sm btn-info mr-1"
          >
            <i className="fa fa-pencil mr-1" /> Edit
          </button>
          <button
            onClick={() => btnDel(row.getValue())}
            className="btn btn-sm btn-danger mr-1"
          >
            <i className="fa fa-trash mr-1" />
            Hapus
          </button>
        </>
      ),
    },
    {
      name: "Name",
      header: "NAMA GRUP",
    },
    {
      name: "Layers",
      header: "TOTAL LAYER",
      selector: ({ row }) => (
        <span className="badge badge-success">
          <b>{row.original.Layers.length}</b> URL LAYER
        </span>
      ),
    },
    {
      name: "Description",
      header: "DESKRIPSI",
    },
  ];

  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12">
        <div className="card planned_task">
          <div className="header">
            <h2>Tabel Data</h2>
          </div>
          <div className="body">
            <DataTable data={dataMapLayerGroups} column={jsonColumn} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTable;
