import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actFormKuisioner,
  actGetRespondenById,
  actGetWilReferences,
  actRespondenUpdate,
} from "src/redux/actions";
import { useParams, useHistory } from "react-router-dom";
import {
  Combobox,
  TextCheckbox,
  TextDate,
  TextInput,
  TextRadio,
} from "src/components/atoms";
import {
  FUNCCheckDateDmy,
  FUNCDateDmytoYmd,
  FUNCDateToString,
  tipeInputKuisioner,
} from "src/utils";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import { IMGUserEmpty } from "src/assets/images/dummy";

const SectionView = () => {
  let params = useParams();
  let history = useHistory();
  const [dataPertanyaans, setDataPertanyaans] = useState([]);
  const [tabNo, setTabNo] = useState(0);
  const dataKuisionerById = useSelector(
    (state) => state.kuisionerReducer.dataKuisionerById
  );
  const dataRespondenById = useSelector(
    (state) => state.kuisionerReducer.dataRespondenById
  );
  const dispatch = useDispatch();
  const hookForm = useForm();
  const { handleSubmit, control, reset } = hookForm;

  const hookFieldArray = useFieldArray({
    control,
    name: "Pertanyaans",
  });
  const { fields, append } = hookFieldArray;

  useEffect(() => {
    dispatch(actGetRespondenById(params.id, false, true));
    dispatch(actGetWilReferences());
  }, [dispatch, params.id]);

  useEffect(() => {
    setDataPertanyaans(dataRespondenById?.KelompokPertanyaans[0]);
  }, [dataRespondenById]);

  useEffect(() => {
    reset();
    dataPertanyaans?.Pertanyaans?.forEach((item) => {
      append(item);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPertanyaans]);

  const btnSave = async (data) => {
    const dataArr = data?.Pertanyaans.map((item) => {
      let jawaban = item.Jawaban;

      if (item.Tipejawaban?.Id === tipeInputKuisioner.pilihan_multi) {
        const dataPilihans = item.Jawabanori.Pilihans.map((itemx) => {
          const filter = item.Jawaban?.Pilihans?.find(
            (x) => x.JawabanPilihanId === itemx.JawabanPilihanId
          );
          return {
            ...itemx,
            Ispilih: filter?.Ispilih,
            Stringvalue:
              filter?.Kode === "99" ? item.Jawaban.StringvalueLainnya : null,
          };
        });
        jawaban = { ...item.Jawaban, Pilihans: dataPilihans };
      } else if (item.Tipejawaban?.Id === tipeInputKuisioner.pilihan) {
        const dataPilihans = item.Jawabanori.Pilihans.map((itemx) => {
          let jaw = item.Jawaban?.Stringvalue[0]?.value;
          if (item.Jawaban?.Stringvalue[0]?.value === undefined)
            jaw = item.Jawabanori.Stringvalue;

          const isPilih = itemx.JawabanPilihanId === jaw;
          return {
            ...itemx,
            Ispilih: isPilih,
            Stringvalue: isPilih ? item.Jawaban?.StringvalueLainnya : null,
          };
        });
        let jaw = item.Jawaban?.Stringvalue[0]?.value;
        if (item.Jawaban?.Stringvalue[0]?.value === undefined)
          jaw = item.Jawabanori.Stringvalue;
        jawaban = {
          ...item.Jawaban,
          Stringvalue: jaw,
          Pilihans: dataPilihans,
        };
      } else if (item.Tipejawaban?.Id === tipeInputKuisioner.kondisi) {
        const dataPilihans = item.Jawabanori.Pilihans.map((itemx) => ({
          ...itemx,
          Ispilih: itemx.JawabanPilihanId === item.Jawaban?.Stringvalue,
        }));
        jawaban = {
          ...item.Jawaban,
          Stringvalue: item.Jawaban?.Stringvalue,
          Pilihans: dataPilihans,
        };
      } else if (item.Tipejawaban?.Id === tipeInputKuisioner.isian_tanggal) {
        jawaban = {
          ...item.Jawaban,
          Datetimevalue: item.Jawaban?.Datetimevalue
            ? FUNCDateToString(item.Jawaban?.Datetimevalue)
            : "",
        };
      }

      return {
        ...item,
        KelompokPertanyaanId: item.KelompokPertanyaanId,
        PertanyaanId: item.PertanyaanId,
        Jawaban: jawaban,
      };
    });

    dispatch(actRespondenUpdate(params.id, dataArr, params.customid));
  };

  return (
    <div className="row clearfix">
      <div className="col-md-6">
        <div className="card">
          <div className="header">
            <h2>Kuesioner Info</h2>
          </div>
          <div className="body table-responsive">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>ID</th>
                  <td>{dataRespondenById?.Id}</td>
                </tr>
                <tr>
                  <th>KUISIONER ID</th>
                  <td>
                    <code>{dataRespondenById?.Kuisionerid}</code>
                  </td>
                </tr>
                <tr>
                  <th>{dataKuisionerById?.Ketkodedata?.toUpperCase()}</th>
                  <td>{dataRespondenById?.Kodedata}</td>
                </tr>
                <tr>
                  <th>LOKASI</th>
                  <td>
                    {dataRespondenById?.Latitude?.toFixed(6)},{" "}
                    {dataRespondenById?.Longitude?.toFixed(6)}{" "}
                    <a
                      href={`https://maps.google.com/?q=${dataRespondenById?.Latitude},${dataRespondenById?.Longitude}`}
                      className="badge badge-success"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Google Maps
                    </a>
                  </td>
                </tr>
                <tr>
                  <th>CATATAN</th>
                  <td>{dataRespondenById?.Catatan}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card">
          <div className="header">
            <h2>Data Responden</h2>
          </div>
          <div className="body table-responsive">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>NAMA</th>
                  <td>{dataRespondenById?.Nama}</td>
                </tr>
                <tr>
                  <th>ALAMAT</th>
                  <td>{dataRespondenById?.Alamat}</td>
                </tr>
                <tr>
                  <th>NO TELEPON</th>
                  <td>{dataRespondenById?.Notelpon}</td>
                </tr>
                <tr>
                  <th>NO HANDPHONE</th>
                  <td>{dataRespondenById?.Nohandphone}</td>
                </tr>
                <tr>
                  <th>EMAIL</th>
                  <td>{dataRespondenById?.Email}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h2>Kelompok Pertanyaan</h2>
              </div>
              <div className="body">
                <ul className="list-unstyled feeds_widget">
                  {dataRespondenById?.KelompokPertanyaans?.map(
                    (item, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => {
                            setDataPertanyaans(item);
                            setTabNo(index);
                          }}
                          className={
                            tabNo === index ? "bg-warning" : "bg-white"
                          }
                        >
                          <div className="feeds-left">
                            <h6>{item.Kode}.</h6>
                          </div>
                          <div className="feeds-body">
                            <h4 className="title">{item.Teks}</h4>
                          </div>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h2>File Responden</h2>
              </div>
              <div className="body table-responsive">
                {dataRespondenById?.Files?.map((item, index) => {
                  return (
                    <table className="table table-bordered" key={index}>
                      <tbody>
                        <tr>
                          <th style={{ width: "50px" }}>Nama</th>
                          <td>{item?.JenisFile?.Nama}</td>
                        </tr>
                        <tr>
                          <th>Judul</th>
                          <td>{item?.Judul}</td>
                        </tr>
                        <tr>
                          <th>File</th>
                          <td>
                            {" "}
                            <img
                              alt="img-user"
                              className="media-object rounded"
                              src={`${item.Nama}`}
                              style={{
                                objectFit: "cover",
                                width: 50,
                                height: 50,
                              }}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = IMGUserEmpty;
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Catatan</th>
                          <td>{item?.Catatan}</td>
                        </tr>
                      </tbody>
                    </table>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-8">
        <div className="card">
          <div className="header">
            <h2>Form Kuesioner</h2>
          </div>
          <div className="body">
            <form onSubmit={handleSubmit(btnSave)}>
              <div className="list-group">
                {fields?.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <ContainerKuisioner
                        key={index}
                        index={index}
                        data={item}
                        hookForm={hookForm}
                      />
                    </Fragment>
                  );
                })}
              </div>

              <div className="col-md-12 d-flex justify-content-end mt-5">
                <button
                  type="button"
                  className="btn btn-dark mr-2"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-arrow-left"></i> <span>Kembali</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContainerKuisioner = ({ data, index, hookForm }) => {
  const { setValue } = hookForm;
  const [dataPilihans, setDataPilihans] = useState([]);
  const formKuisioner = useSelector(
    (state) => state.kuisionerReducer.formKuisioner
  );

  const {
    Jawaban,
    Tipejawaban,
    KelompokPertanyaanId,
    PertanyaanId,
    Teks,
    Ispertanyaan,
  } = data;

  useEffect(() => {
    if (Ispertanyaan) {
      const arrdataPilihans = [];
      Jawaban?.Pilihans?.forEach((item, i) => {
        if (
          Tipejawaban?.Id === tipeInputKuisioner.pilihan_multi &&
          item.Ispilih
        ) {
          setValue(
            `Pertanyaans[${index}].Jawaban.Pilihans[${i}].JawabanPilihanId`,
            item.JawabanPilihanId
          );
        }
        if (item.JawabanPilihanId)
          arrdataPilihans.push({
            value: item.JawabanPilihanId,
            label: item.Teks,
            ispilih: item.Ispilih,
            kode: item.Kode,
            stringvalue: item.Stringvalue,
          });
      });
      setDataPilihans(arrdataPilihans);

      setValue(
        `Pertanyaans[${index}].KelompokPertanyaanId`,
        KelompokPertanyaanId
      );
      setValue(`Pertanyaans[${index}].PertanyaanId`, PertanyaanId);
      setValue(`Pertanyaans[${index}].Teks`, Teks);
      setValue(`Pertanyaans[${index}].Tipejawaban`, Tipejawaban);
      setValue(`Pertanyaans[${index}].Jawabanori`, Jawaban);

      setValue(`Pertanyaans[${index}].Jawaban.StringvalueLainnya`, null);
      if (
        Tipejawaban?.Id === tipeInputKuisioner.pilihan ||
        Tipejawaban?.Id === tipeInputKuisioner.pilihan_multi
      ) {
        const filterpilihan = Jawaban?.Pilihans?.find(
          (x) => x.Ispilih === true && x.Kode === "99"
        );
        if (Tipejawaban?.Id === tipeInputKuisioner.pilihan)
          setValue(`Pertanyaans[${index}].Jawaban.Stringvalue`, [
            {
              label: filterpilihan?.Teks,
              value: filterpilihan?.JawabanPilihanId,
            },
          ]);
        setValue(
          `Pertanyaans[${index}].Jawaban.StringvalueLainnya`,
          filterpilihan?.Stringvalue
        );
      } else if (Tipejawaban?.Id === tipeInputKuisioner.kondisi) {
        const filterpilihan = Jawaban?.Pilihans?.find(
          (x) => x.Ispilih === true
        );
        setValue(
          `Pertanyaans[${index}].Jawaban.Stringvalue`,
          filterpilihan?.JawabanPilihanId
        );
      } else {
        setValue(
          `Pertanyaans[${index}].Jawaban.Stringvalue`,
          Jawaban?.Stringvalue
        );
      }
      setValue(
        `Pertanyaans[${index}].Jawaban.Numbervalue`,
        Jawaban?.Numbervalue
      );

      setValue(
        `Pertanyaans[${index}].Jawaban.Datetimevalue`,
        Jawaban?.Datetimevalue ? FUNCDateDmytoYmd(Jawaban?.Datetimevalue) : ""
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, index]);

  useEffect(() => {
    if (Tipejawaban?.Refjawabanpilihanid === "ref_kabkot") {
      const arrdataPilihans = [];
      Jawaban?.Pilihans?.forEach((item) => {
        if (
          parseInt(item.JawabanPilihanId.slice(0, 2)) ===
          parseInt(formKuisioner?.dynamicProvinsi)
        )
          arrdataPilihans.push({
            value: item.JawabanPilihanId,
            label: item.Teks,
            ispilih: item.Ispilih,
            kode: item.Kode,
            stringvalue: item.Stringvalue,
          });
      });
      setDataPilihans(arrdataPilihans);
    }
    if (Tipejawaban?.Refjawabanpilihanid === "ref_kecamatan") {
      const arrdataPilihans = [];
      Jawaban?.Pilihans?.forEach((item) => {
        if (
          parseInt(item.JawabanPilihanId.slice(0, 4)) ===
          parseInt(formKuisioner?.dynamicKabkot)
        )
          arrdataPilihans.push({
            value: item.JawabanPilihanId,
            label: item.Teks,
            ispilih: item.Ispilih,
            kode: item.Kode,
            stringvalue: item.Stringvalue,
          });
      });
      setDataPilihans(arrdataPilihans);
    }
    if (Tipejawaban?.Refjawabanpilihanid === "ref_desa") {
      const arrdataPilihans = [];
      Jawaban?.Pilihans?.forEach((item) => {
        if (
          parseInt(item.JawabanPilihanId.slice(0, 6)) ===
          parseInt(formKuisioner?.dynamicKecamatan)
        )
          arrdataPilihans.push({
            value: item.JawabanPilihanId,
            label: item.Teks,
            ispilih: item.Ispilih,
            kode: item.Kode,
            stringvalue: item.Stringvalue,
          });
      });
      setDataPilihans(arrdataPilihans);
    }
    if (Tipejawaban?.Refjawabanpilihanid === "ref_rw") {
      const arrdataPilihans = [];
      Jawaban?.Pilihans?.forEach((item) => {
        if (
          parseInt(item.JawabanPilihanId.slice(0, 10)) ===
          parseInt(formKuisioner?.dynamicDesa)
        )
          arrdataPilihans.push({
            value: item.JawabanPilihanId,
            label: item.Teks,
            ispilih: item.Ispilih,
            kode: item.Kode,
            stringvalue: item.Stringvalue,
          });
      });
      setDataPilihans(arrdataPilihans);
    }
    if (Tipejawaban?.Refjawabanpilihanid === "ref_rt") {
      const arrdataPilihans = [];
      Jawaban?.Pilihans?.forEach((item) => {
        if (
          parseInt(item.JawabanPilihanId.slice(0, 12)) ===
          parseInt(formKuisioner?.dynamicRw)
        )
          arrdataPilihans.push({
            value: item.JawabanPilihanId,
            label: item.Teks,
            ispilih: item.Ispilih,
            kode: item.Kode,
            stringvalue: item.Stringvalue,
          });
      });
      setDataPilihans(arrdataPilihans);
    }
  }, [Jawaban?.Pilihans, Tipejawaban, formKuisioner]);

  return !data.Ispertanyaan ? (
    <KuisionerForm data={data} />
  ) : (
    <SubKuisionerJawabanForm
      index={index}
      data={data}
      hookForm={hookForm}
      dataPilihans={dataPilihans}
    />
  );
};

const KuisionerForm = ({ data }) => {
  return (
    <div className="list-group-item list-group-item-action flex-column align-items-start">
      <div className="d-flex w-100">
        <small className="mr-2">{data.Kode}</small>
        <h6 className="mb-1">{data.Teks}</h6>
      </div>
    </div>
  );
};

const SubKuisionerJawabanForm = ({ index, data, hookForm, dataPilihans }) => {
  const { register, control, getValues } = hookForm;
  const [isTrigger, setIsTrigger] = useState(false);
  const getValue = getValues();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsTrigger(false);
    if (getValue.Pertanyaans[index].Jawaban?.StringvalueLainnya)
      setIsTrigger(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPilihans]);

  const onWil = (val) => {
    val = val[0];
    if (data.Tipejawaban?.Refjawabanpilihanid === "ref_kecamatan")
      dispatch(actFormKuisioner("dynamicKecamatan", val?.value));
    if (data.Tipejawaban?.Refjawabanpilihanid === "ref_desa")
      dispatch(actFormKuisioner("dynamicDesa", val?.value));
    if (data.Tipejawaban?.Refjawabanpilihanid === "ref_rw")
      dispatch(actFormKuisioner("dynamicRw", val?.value));
    if (data.Tipejawaban?.Refjawabanpilihanid === "ref_rt")
      dispatch(actFormKuisioner("dynamicRt", val?.value));
  };

  const JawabanField = () => {
    const renderKondisiField = () => (
      <>
        <Controller
          control={control}
          name={`Pertanyaans[${index}].Jawaban.Stringvalue`}
          render={({ field }) => (
            <TextRadio
              label="Jawaban Pilihan"
              name={`Pertanyaans[${index}].Jawaban.StringValue`}
              options={dataPilihans}
              value={field.value}
              onChange={(val) => {
                field.onChange(val);
              }}
              disabled={true}
            />
          )}
          rules={{
            required: false,
          }}
        />
      </>
    );

    const renderIsianBilanganField = () => (
      <TextInput
        type="number"
        label={"Jawaban Angka"}
        hook={register(`Pertanyaans[${index}].Jawaban.Numbervalue`, {
          required: false,
        })}
        disabled={true}
      />
    );

    const renderIsianTeksField = () => (
      <TextInput
        type="text"
        label={"Jawaban Teks"}
        hook={register(`Pertanyaans[${index}].Jawaban.Stringvalue`, {
          required: false,
        })}
        disabled={true}
      />
    );

    const renderPilihanField = () => (
      <Controller
        control={control}
        name={`Pertanyaans[${index}].Jawaban.Stringvalue`}
        render={({ field }) => (
          <Combobox
            label={"Jawaban Pilihan"}
            options={dataPilihans}
            values={dataPilihans.filter((x) => x.ispilih)}
            onChange={(val) => {
              field.onChange(val);
              if (val?.[0]?.kode === "99") {
                setIsTrigger(true);
              } else {
                setIsTrigger(false);
              }
            }}
            disabled={true}
          />
        )}
        rules={{
          required: false,
        }}
      />
    );

    const renderPilihanMultiField = () => (
      <>
        <div className="form-group">
          <label>Jawaban Pilihan</label>
          {dataPilihans.map((item, i) => {
            return (
              <div key={i}>
                <Controller
                  control={control}
                  name={`Pertanyaans[${index}].Jawaban.Pilihans[${i}].Ispilih`}
                  defaultValue={item.ispilih}
                  render={({ field }) => (
                    <TextCheckbox
                      label={item.label}
                      value={item.value}
                      onChange={(val) => {
                        field.onChange(val);
                        if (item?.kode === "99") {
                          if (val.target.checked) {
                            setIsTrigger(true);
                          } else {
                            setIsTrigger(false);
                          }
                        }
                      }}
                      checked={field.value}
                      disabled={true}
                    />
                  )}
                  rules={{
                    required: false,
                  }}
                />
              </div>
            );
          })}
        </div>
      </>
    );

    const renderIsianTanggalField = () => (
      <Controller
        control={control}
        name={`Pertanyaans[${index}].Jawaban.Datetimevalue`}
        defaultValue={getValues().Pertanyaans[index].Jawaban.Datetimevalue}
        render={({ field }) => {
          const date = getValues().Pertanyaans[index].Jawaban.Datetimevalue
            ? new Date(
                !FUNCCheckDateDmy(
                  getValues().Pertanyaans[index].Jawaban.Datetimevalue
                )
                  ? field.value
                  : FUNCDateDmytoYmd(
                      getValues().Pertanyaans[index].Jawaban.Datetimevalue
                    )
              )
            : "";
          return (
            <>
              <TextDate
                label={"Jawaban Tanggal"}
                selected={date}
                onChange={(date) => field.onChange(date)}
                dateFormat="dd-MM-yyyy"
                customInput={
                  <ReactInputMask
                    mask="99-99-9999"
                    onChange={field.onChange}
                    value={field.value}
                    maskChar=""
                  />
                }
                disabled={true}
              />
            </>
          );
        }}
        rules={{
          required: false,
        }}
      />
    );

    const renderPilihanDynamicField = () => (
      <Controller
        control={control}
        name={`Pertanyaans[${index}].Jawaban.Stringvalue`}
        render={({ field }) => (
          <Combobox
            label={"Jawaban Pilihan"}
            options={dataPilihans}
            values={dataPilihans.filter((x) => x.ispilih)}
            onChange={(val) => {
              field.onChange(val);
              onWil(val);
            }}
            disabled={true}
          />
        )}
        rules={{
          required: false,
        }}
      />
    );

    if (
      data.Tipejawaban?.Id === tipeInputKuisioner.pilihan &&
      data.Tipejawaban?.Istable
    ) {
      return renderPilihanDynamicField();
    }
    if (data.Tipejawaban?.Id === tipeInputKuisioner.kondisi) {
      return renderKondisiField();
    }
    if (data.Tipejawaban?.Id === tipeInputKuisioner.isian_bilangan) {
      return renderIsianBilanganField();
    }
    if (data.Tipejawaban?.Id === tipeInputKuisioner.isian_teks) {
      return renderIsianTeksField();
    }
    if (data.Tipejawaban?.Id === tipeInputKuisioner.pilihan) {
      return renderPilihanField();
    }
    if (data.Tipejawaban?.Id === tipeInputKuisioner.pilihan_multi) {
      return renderPilihanMultiField();
    }
    if (data.Tipejawaban?.Id === tipeInputKuisioner.isian_tanggal) {
      return renderIsianTanggalField();
    }

    return <></>;
  };

  return (
    <div className="list-group-item flex-column align-items-start pl-5 pt-4">
      <div className="d-flex w-100">
        <small className="text-muted mr-2">{data.Kode}</small>
        <p className="mb-1">{data.Teks}</p>
      </div>
      {/* JAWABAN */}
      <div>{JawabanField()}</div>
      {isTrigger && (
        <TextInput
          type="text"
          label={"Jawaban Lainnya"}
          hook={register(`Pertanyaans[${index}].Jawaban.StringvalueLainnya`, {
            required: false,
          })}
          disabled={true}
        />
      )}
    </div>
  );
};

export default SectionView;
