/* eslint-disable import/no-anonymous-default-export */
import { instanceAuth } from "src/utils";

class HomeService {
  getMap() {
    return instanceAuth.get("/home/GetMap");
  }
  getCharts(id) {
    return instanceAuth.get(`/home/GetCharts?tahun=${id}`);
  }
}

export default new HomeService();
