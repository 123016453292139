import { instanceAuth } from "src/utils";

class UserService {
  getInfoUser() {
    return instanceAuth.get("/usr/GetInfoUser");
  }
  getAll() {
    return instanceAuth.get("/usr/GetUsers");
  }
  getByStatus(status) {
    return instanceAuth.get(`/usr/getUserByStatus?status=${status}`);
  }
  getById(id) {
    return instanceAuth.get(`/usr/getUserById?id=${id}`);
  }
  add(data) {
    return instanceAuth.post("/usr/userAdd", data);
  }
  edit(data) {
    return instanceAuth.post("/usr/userEdit", data);
  }
  delete(data) {
    return instanceAuth.post("/usr/userDelete", data);
  }
  editProfile(data) {
    return instanceAuth.post("/usr/userEditProfile", data);
  }
  activate(data) {
    return instanceAuth.post("/usr/userActivate", data);
  }
  deactivate(data) {
    return instanceAuth.post("/usr/userDeactivate", data);
  }
  changePassword(data) {
    return instanceAuth.post("/usr/UserPasswordChange", data);
  }
  getRoles() {
    return instanceAuth.get("/usr/getRoles");
  }
  getMenus() {
    return instanceAuth.get("/usr/getMenus");
  }
  getApplTasksByUserId(data) {
    return instanceAuth.get("/usr/GetApplTasksByUserId", data);
  }
  delegateMenu(data) {
    return instanceAuth.post("/usr/DelegateMenu", data);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new UserService();
