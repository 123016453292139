import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IMGUserEmpty } from "src/assets/images/dummy";
import { MapContainer } from "src/components/organisms";
import { actGetCharts } from "src/redux/actions";

const SectionGraph = () => {
  const { dataGetCharts } = useSelector((state) => state.homeReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actGetCharts());
  }, [dispatch]);

  return (
    <div className="row clearfix">
      <div className="col-md-6">
        <div className="card">
          <div className="header">
            <h2>Map Sebaran Survey</h2>
          </div>
          <div className="body">
            <div style={{ height: "60vh" }}>
              <MapContainer />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card">
          <div className="header">
            <h2>Terakhir Input Survey</h2>
          </div>
          <div className="body">
            <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
              <ul className="right_chat list-unstyled mr-2">
                {dataGetCharts?.PetugasSurveys?.map((item, index) => (
                  <li key={index} className="online">
                    <div className="media">
                      <img
                        alt="img-user"
                        className="media-object"
                        src={item.Photo}
                        style={{ objectFit: "cover", width: 40, height: 40 }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = IMGUserEmpty;
                        }}
                      />
                      <div className="media-body">
                        <span className="name">{item.Nama}</span>
                        <small className="float-left">
                          {item.Tanggal} {item.Jam}
                        </small>
                        <span className="message">
                          {" "}
                          <i className="icon-pencil mr-1" /> Input data{" "}
                          {item.Teks}
                        </span>
                        <span className="badge badge-outline status"></span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card">
          <div className="header">
            <h2>Terakhir Input Kuisioner</h2>
          </div>
          <div className="body">
            <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
              <ul className="right_chat list-unstyled mr-2">
                {dataGetCharts?.PetugasKuisioners?.map((item, index) => (
                  <li key={index} className="online">
                    <div className="media">
                      <img
                        alt="img-user"
                        className="media-object"
                        src={item.Photo}
                        style={{ objectFit: "cover", width: 40, height: 40 }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = IMGUserEmpty;
                        }}
                      />

                      <div className="media-body">
                        <span className="name">{item.Nama} </span>
                        <small className="float-left">
                          {item.Tanggal} {item.Jam}
                        </small>
                        <span className="message">
                          <i className="icon-pencil mr-1" /> Input data{" "}
                          {item.Teks}
                        </span>
                        <span className="badge badge-outline status"></span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGraph;
