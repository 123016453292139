/* eslint-disable import/no-anonymous-default-export */
import { instanceAuth } from "src/utils";

class SdiService {
  getPublicationDatasetTypes() {
    return instanceAuth.get("/Sdi/GetPublicationDatasetTypes");
  }
  getLicenses() {
    return instanceAuth.get("/Sdi/GetLicenses");
  }
  getDataScopes() {
    return instanceAuth.get("/Sdi/GetDataScopes");
  }
  getMasterTables() {
    return instanceAuth.get("/Sdi/GetMasterTables");
  }
  getFrequencies() {
    return instanceAuth.get("/Sdi/GetFrequencies");
  }
  getTags() {
    return instanceAuth.get("/Sdi/getTags");
  }
  getAgencies() {
    return instanceAuth.get("/Sdi/getAgencies");
  }
  getPublicationDocumentTypes() {
    return instanceAuth.get("/Sdi/getPublicationDocumentTypes");
  }
  getDataTopics() {
    return instanceAuth.get("/Sdi/getDataTopics");
  }
  getDataCategories(topicId) {
    return instanceAuth.get(`/Sdi/getDataCategories?topicId=${topicId}`);
  }
  getDatasetTypes() {
    return instanceAuth.get("/Sdi/getDatasetTypes");
  }
  getFieldTypes() {
    return instanceAuth.get("/Sdi/getFieldTypes");
  }
  getYears() {
    return instanceAuth.get("/Sdi/getYears");
  }
  saveTag(data) {
    return instanceAuth.post("/Sdi/SaveTag", data);
  }
  saveDataset(data, type) {
    return instanceAuth.post(`/Sdi/DatasetSave?type=${type}`, data);
  }
}

export default new SdiService();
