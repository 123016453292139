import React from "react";
import { LayoutClient } from "src/components/molecules";
import SectionTable from "./parts/SectionTable";

const ManageSurveyList = () => {
  return (
    <LayoutClient
      page="Manajemen Survey"
      breadcrumb={[{ name: "Manajemen Survey", navigate: "" }]}
    >
      <SectionTable />
    </LayoutClient>
  );
};

export default ManageSurveyList;
