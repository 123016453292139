import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actRegister, actUserGetJenisFiles } from "src/redux/actions";
import { TextArea, TextInput, TextUpload } from "src/components/atoms";
import { Controller, useForm } from "react-hook-form";
import {
  FUNCValidateUploadFileExtension,
  FUNCValidateUploadFileSize,
} from "src/utils";
import { IMGLogo } from "src/assets/images/dummy";

const SectionForm = () => {
  const { dataUserJenisFiles } = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actUserGetJenisFiles());
  }, [dispatch]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm();

  const onFile = (val, item, index) => {
    FUNCValidateUploadFileSize(val.target, 2048, "2MB");
    FUNCValidateUploadFileExtension(val.target, ["png", "jpg"]);
    let file = {
      type: item.value,
      title: item.label,
      fileUpload: val.target.files[0],
    };
    setValue(`files[${index}]`, file, { shouldValidate: true });
  };

  const btnSave = (data) => dispatch(actRegister(data));

  return (
    <div className="auth-box-secondary">
      <div className="card">
        <div className="header">
          <img
            src={IMGLogo}
            alt="Lucid"
            style={{
              width: "100%",
              height: "80px",
              objectFit: "contain",
            }}
          />
          <p className="lead text-center">Buat akun survey, sekarang!</p>
        </div>
        <div className="body">
          <form onSubmit={handleSubmit(btnSave)}>
            <div>
              <div className="row">
                <div className="col-md-6">
                  <TextInput
                    label={"Username"}
                    hook={register("username", { required: true })}
                    isError={errors.username}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    type="email"
                    label={"Email"}
                    hook={register("email", { required: true })}
                    isError={errors.email}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <TextInput
                    label={"Nama Depan"}
                    hook={register("firstName", { required: true })}
                    isError={errors.firstName}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    label={"Nama Belakang"}
                    hook={register("middleName", { required: false })}
                    isError={errors.middleName}
                  />
                </div>
              </div>

              <TextArea
                label={"Alamat Tinggal"}
                hook={register("address", { required: true })}
                isError={errors.address}
              />
              <div className="row">
                {dataUserJenisFiles?.map((item, index) => {
                  return (
                    <div className="col-md-6">
                      <Controller
                        key={index}
                        control={control}
                        name={`files[${index}]`}
                        render={() => (
                          <TextUpload
                            label={item.label}
                            name={`files[${index}]`}
                            fileSelect={
                              getValues(`files[${index}]`)?.fileUpload?.name
                            }
                            onChange={(val) => {
                              onFile(val, item, index);
                            }}
                            onDel={() => setValue(`files[${index}]`, null)}
                            isError={errors.files?.[index]}
                          />
                        )}
                        rules={{
                          required: true,
                        }}
                      />
                    </div>
                  );
                })}
              </div>

              <button
                className="btn btn-primary btn-lg btn-block"
                type="submit"
              >
                REGISTER
              </button>
              <div className="bottom text-center mt-3">
                <span className="helper-text">
                  Sudah memiliki akun? <a href="login">Login</a>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SectionForm;
