import React, { useEffect } from "react";
import { LayoutClient } from "src/components/molecules";
import SectionForm from "./parts/SectionForm";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { actGetJenisSurveyById } from "src/redux/actions";

const SurveyAdd = (props) => {
  let { customid } = useParams();
  const jenisSurveyId = customid;

  const { dataJenisSurveyById } = useSelector((state) => state.refReducer);
  const dispatch = useDispatch();

  const path = props?.location?.pathname;
  const modifiedPath = path.substring(0, path.lastIndexOf("/"));

  useEffect(() => {
    dispatch(actGetJenisSurveyById(jenisSurveyId));
  }, [dispatch, jenisSurveyId]);

  return (
    <LayoutClient
      page={`Tambah Data ${dataJenisSurveyById?.Namapendek}`}
      breadcrumb={[
        {
          name: dataJenisSurveyById?.Namapendek,
          navigate: `${modifiedPath}/list`,
        },
        { name: "Tambah Data", navigate: "" },
      ]}
    >
      <SectionForm />
    </LayoutClient>
  );
};

export default SurveyAdd;
