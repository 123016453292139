import React, { useEffect } from "react";
import { LayoutClient } from "src/components/molecules";
import SectionKuisioner from "./parts/SectionKuisioner";
import { useDispatch, useSelector } from "react-redux";
import { actGetKuisionerById } from "src/redux/actions";
import { useParams } from "react-router-dom";

const KuisionerResponden = (props) => {
  let { customid } = useParams();
  const jenisKuisionerId = customid;

  const dataKuisionerById = useSelector(
    (state) => state.kuisionerReducer.dataKuisionerById
  );
  const dispatch = useDispatch();

  const path = props?.location?.pathname;
  const parts = path.split("/");
  const modifiedPath = `/${parts[1]}/${customid}`;

  useEffect(() => {
    dispatch(actGetKuisionerById(jenisKuisionerId));
  }, [dispatch, jenisKuisionerId]);

  return (
    <LayoutClient
      page={`Perbarui Kuesioner ${dataKuisionerById?.NamaPendek}`}
      breadcrumb={[
        {
          name: dataKuisionerById?.NamaPendek,
          navigate: `${modifiedPath}/list`,
        },
        {
          name: `Perbarui Kuesioner`,
          navigate: "",
        },
      ]}
    >
      <SectionKuisioner />
    </LayoutClient>
  );
};

export default KuisionerResponden;
