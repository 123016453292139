import { instanceAuth } from "src/utils";

class SurveyService {
  // SERVICE REF
  getRefJenisSurveys() {
    return instanceAuth.get("/survey-ref/GetRefJenisSurveys");
  }
  getJenisSurveys() {
    return instanceAuth.get("/survey-ref/GetJenisSurveys");
  }
  getJenisSurvey(id) {
    return instanceAuth.get(`/survey-ref/GetJenisSurvey?id=${id}`);
  }

  addJenisSurvey(data) {
    return instanceAuth.post("/survey-ref/AddJenissurvey", data);
  }
  editJenisSurvey(data) {
    return instanceAuth.post("/survey-ref/EditJenissurvey", data);
  }
  setAktifJenissurvey(data) {
    return instanceAuth.post("/survey-ref/SetAktifJenissurvey", data);
  }
  setTidakAktifJenissurvey(data) {
    return instanceAuth.post("/survey-ref/SetTidakAktifJenissurvey", data);
  }

  getJenisFileSurveys(data) {
    return instanceAuth.get("/survey-ref/GetJenisFileSurveys", data);
  }
  getSearchAtributs(id) {
    return instanceAuth.get(
      `/survey-ref/GetSearchAtributs?jenisSurveyId=${id}`
    );
  }
  getTemplateSurvey(id) {
    return instanceAuth.get(
      `/survey-ref/GetTemplateSurvey?jenisSurveyId=${id}`
    );
  }

  // SERVICE SURVEY
  addSurvey(data) {
    return instanceAuth.post("/survey/SurveyAdd", data, {
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
  }
  editSurvey(data) {
    return instanceAuth.post("/survey/SurveyEdit", data, {
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
  }
  deleteSurvey(id) {
    return instanceAuth.post(`/survey/SurveyDelete?id=${id}`);
  }
  getSurveys(id, pageNum = 1, rows = 10) {
    return instanceAuth.get(
      `/survey/GetSurveys?jenisSurveyId=${id}&pageNum=${pageNum}&rows=${rows}`
    );
  }
  getSurvey(id) {
    return instanceAuth.get(`/survey/GetSurvey?id=${id}`);
  }
  getSurveysByJenis(id) {
    return instanceAuth.get(`/survey/GetSurveys?jenisSurveyId=${id}`);
  }
  getSurveysSearchBy(id, pageNum = 1, rows = 10, atributId, atributValue) {
    return instanceAuth.get(
      `/survey/GetSurveysSearchBy?jenisSurveyId=${id}&pageNum=${pageNum}&rows=${rows}&atributId=${atributId}&atributValue=${atributValue}`
    );
  }
  getSurveysByDate(data) {
    return instanceAuth.get("/survey/GetSurveysByDate", data);
  }
  getSurveysByMonth(data) {
    return instanceAuth.get("/survey/GetSurveysByMonth", data);
  }
  getSurveysByUser() {
    return instanceAuth.get("/survey/GetSurveysByUser");
  }

  downloadSurveysFiles(id, atributId = "", atributValue = "") {
    return instanceAuth.get(
      `/survey/DownloadSurveyFiles?jenisSurveyId=${id}&atributId=${atributId}&atributValue=${atributValue}`,
      {
        responseType: "arraybuffer",
      }
    );
  }
  downloadSurveysExcel(
    id,
    minDate,
    maxDate,
    atributId = "",
    atributValue = ""
  ) {
    return instanceAuth.get(
      `/survey/DownloadSurveysExcel?jenisSurveyId=${id}&minDate=${minDate}&maxDate=${maxDate}&atributId=${atributId}&atributValue=${atributValue}`,
      { responseType: "arraybuffer" }
    );
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new SurveyService();
