const initialState = {
  formSurvey: {
    dynamicProvinsi: process.env.REACT_APP_KODE_PROV,
    dynamicKabkot: process.env.REACT_APP_KODE_KABKOT,
  },
  downloadSurveyFiles: null,
  downloadSurveyExcel: null,
  dataSurveyColumns: [],
  dataSurveys: [],
  dataSurvey: null,
  dataSurveyTemplate: null,
  dataSurveyAttribut: null,
};

const surveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FORM_SURVEY":
      return {
        ...state,
        formSurvey: {
          ...state.formSurvey,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_SURVEY":
      return { ...state, formSurvey: initialState.formSurvey };
    case "DOWNLOAD_SURVEY_FILES":
      return { ...state, downloadSurveyFiles: action.payload };
    case "DOWNLOAD_SURVEY_EXCEL":
      return { ...state, downloadSurveyExcel: action.payload };
    case "DATA_SURVEY_COLUMNS":
      return { ...state, dataSurveyColumns: action.payload };
    case "DATA_SURVEYS":
      return { ...state, dataSurveys: action.payload };
    case "DATA_SURVEY":
      return { ...state, dataSurvey: action.payload };
    case "DATA_SURVEY_TEMPLATE":
      return { ...state, dataSurveyTemplate: action.payload };
    case "DATA_SURVEY_ATTRIBUT":
      return { ...state, dataSurveyAttribut: action.payload };
    default:
      return state;
  }
};

export default surveyReducer;
