import React, { useEffect, useState } from "react";
import { LayoutClient } from "src/components/molecules";
import SectionMetadata from "./parts/SectionMetadata";
import SectionDataset from "./parts/SectionDataset";
import SectionAttribute from "./parts/SectionAttribute";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { actDatasetAdd } from "src/redux/actions";
import { useParams } from "react-router-dom";

const IntegrasiSatudata = () => {
  let { customid } = useParams();

  const dispatch = useDispatch();
  const hook = useForm();
  const [showModalMap, setShowModalMap] = useState(false);
  const [step, setStep] = useState(1);
  const jsonSteps = useState([
    {
      title: "Form Metadata",
      component: () => <SectionMetadata hook={hook} paramId={customid} />,
    },
    {
      title: "Form Dataset",
      component: () => <SectionDataset hook={hook} paramId={customid} />,
    },
    {
      title: "Form Atribut",
      component: () => <SectionAttribute hook={hook} paramId={customid} />,
    },
  ])[0];
  const { handleSubmit, getValues, reset } = hook;

  let titleHeader,
    previewJenis = "";
  if (customid === "survey") {
    titleHeader = "Integrasi Survey";
    previewJenis = "JENIS SURVEY";
  }
  if (customid === "kuisioner") {
    titleHeader = "Integrasi Kuisioner";
    previewJenis = "JENIS KUISIONER";
  }

  useEffect(() => {
    setStep(1);
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, customid]);

  const previous = () => setStep(step <= 1 ? step : step - 1);

  const next = () => setStep(step >= jsonSteps.length ? step : step + 1);

  const onModalMap = () => setShowModalMap(!showModalMap);

  const saveForm = () => {
    if (step === 3) {
      onModalMap();
    } else {
      next();
    }
  };

  const sentApi = () => {
    onModalMap();
    dispatch(actDatasetAdd(getValues(), customid));
  };

  const ButtonFooter = () => {
    if (step === 3) {
      return (
        <>
          <button
            type="button"
            className="btn btn-dark mr-2"
            onClick={previous}
          >
            <i className="fa fa-reply"></i> <span>Kembali</span>
          </button>
          <button type="submit" className="btn btn-success">
            <i className="fa fa-search"></i> <span>Preview</span>
          </button>
        </>
      );
    } else {
      return (
        <>
          {step === 2 && (
            <button
              type="button"
              className="btn btn-dark mr-2"
              onClick={previous}
            >
              <i className="fa fa-reply"></i> <span>Kembali</span>
            </button>
          )}
          <button type="submit" className="btn btn-success">
            <i className="fa fa-arrow-right"></i> <span>Lanjutkan</span>
          </button>
        </>
      );
    }
  };

  const BadgdeTypePublication = ({ type }) => {
    if (type === "Terbuka")
      return <span className="badge badge-success">Terbuka</span>;
    if (type === "Tertutup")
      return <span className="badge badge-danger">Tertutup</span>;
    if (type === "Terbatas")
      return <span className="badge badge-warning">Terbatas</span>;
    return <></>;
  };
  return (
    <LayoutClient
      page={titleHeader}
      breadcrumb={[{ name: "Integrasi Survey", navigate: "" }]}
    >
      {/* ALERT */}
      <div className="alert alert-primary">
        <b>{titleHeader} ke Portal Satu Data</b>
        <p className="mb-0">Hati-hati dalam melakukan integrasi data!</p>
      </div>

      {/* STEP */}
      <form onSubmit={handleSubmit(saveForm)}>
        {jsonSteps.length > 0 ? jsonSteps[step - 1].component() : <></>}
        <div className="col-md-10 mx-auto d-flex justify-content-end pb-5">
          <ButtonFooter />
        </div>
      </form>

      {/* MODEL */}
      <Modal size={"xl"} show={showModalMap} onHide={onModalMap}>
        <Modal.Header closeButton>
          <Modal.Title>Preview Form</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="alert alert-danger">
            <b>Perhatikan Kembali</b>
            <p className="mb-0">Pastikan data-data yang diinput sudah benar!</p>
          </div>
          <hr />
          <div>
            <h6>METADATA</h6>
            <div className="row">
              <div className="col-md-6">
                <div className="body table-responsive">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th>{previewJenis}</th>
                        <td>{getValues()?.jenisSurveyId?.[0]?.label}</td>
                      </tr>
                      <tr>
                        <th>JUDUL</th>
                        <td>{getValues()?.title}</td>
                      </tr>
                      <tr>
                        <th>TIPE PUBLIKASI</th>
                        <td>
                          <BadgdeTypePublication
                            type={getValues()?.publicationType?.[0]?.label}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>LISENSI</th>
                        <td>{getValues()?.licenseId?.[0]?.label}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-6">
                <div className="body table-responsive">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th>CAKUPAN</th>
                        <td>{getValues()?.dataScopeId?.[0]?.label}</td>
                      </tr>
                      <tr>
                        <th>SUMBER</th>
                        <td>{getValues()?.dataSource}</td>
                      </tr>
                      <tr>
                        <th>FREKUENSI</th>
                        <td>
                          {getValues()?.frequencyId?.[0]?.label}
                          {getValues()?.period?.[0]?.label &&
                            `Periode: ${getValues()?.period?.[0]?.label}`}
                        </td>
                      </tr>
                      <tr>
                        <th>TAG</th>
                        <td>
                          {getValues()
                            ?.tagIds?.map((item) => item.label)
                            .join(", ")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <hr />
            <h6>DATASET</h6>
            <div className="body table-responsive">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th>DESKRIPSI</th>
                    <td>{getValues()?.description}</td>
                  </tr>
                  <tr>
                    <th>PRODUSEN DATA</th>
                    <td>{getValues()?.agencyId?.[0]?.label}</td>
                  </tr>
                  <tr>
                    <th>TOPIK</th>
                    <td>{getValues()?.datasetTopicId?.[0]?.label}</td>
                  </tr>
                  <tr>
                    <th>KATEGORI</th>
                    <td>{getValues()?.datasetCategoryId?.[0]?.label}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />
            <h6>ATTRIBUT</h6>
            <div className="body table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>NAMA</th>
                    <th>TIPE</th>
                    <th>PANJANG</th>
                    <th>PUBLIKASI</th>
                    <th>DESKRIPSI</th>
                  </tr>
                </thead>
                <tbody>
                  {getValues().attributes?.map((item, index) => {
                    index++;
                    return (
                      <tr key={index}>
                        <th scope="row">{index++}</th>
                        <td>
                          <input
                            className="form-control"
                            value={item.title}
                            readOnly
                          />
                        </td>
                        <td>
                          <code>{item.fieldTypeId[0]?.label}</code>
                        </td>
                        <td>{item.fieldLength}</td>
                        <td>
                          <BadgdeTypePublication
                            type={item.publicationType[0]?.label}
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            value={item.description}
                            readOnly
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button type="button" className="btn btn-dark" onClick={onModalMap}>
            <i className="fa fa-close"></i> <span>Batalkan</span>
          </button>
          <button type="button" className="btn btn-success" onClick={sentApi}>
            <i className="fa fa-save"></i> <span>Kirim Sekarang</span>
          </button>
        </Modal.Footer>
      </Modal>
    </LayoutClient>
  );
};

export default IntegrasiSatudata;
