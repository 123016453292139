import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "src/components/molecules";
import { actDeleteLayer, actGetLayer, actGetLayers } from "src/redux/actions";
import { useHistory, useParams } from "react-router-dom";

const SectionTable = () => {
  let params = useParams();

  const history = useHistory();
  const dataMapLayers = useSelector((state) => state.mapReducer.dataMapLayers);
  const dispatch = useDispatch();
  useEffect(() => {
    if (dataMapLayers.length === 0) dispatch(actGetLayers(params.groupid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params]);

  const btnEdit = (id) => dispatch(actGetLayer(id, params.groupid, history));
  const btnDel = (id) => dispatch(actDeleteLayer(id, params.groupid));

  const jsonColumn = [
    {
      name: "Id",
      header: "AKSI",
      selector: (row) => (
        <>
          <button
            onClick={() => btnEdit(row.getValue())}
            className="btn btn-sm btn-info mr-1"
          >
            <i className="fa fa-pencil mr-1" /> Edit
          </button>
          <button
            onClick={() => btnDel(row.getValue())}
            className="btn btn-sm btn-danger mr-1"
          >
            <i className="fa fa-trash mr-1" />
            Hapus
          </button>
        </>
      ),
    },
    {
      name: "Type",
      header: "TIPE",
      selector: ({ row }) => <code>{row.original.Type}</code>,
    },
    {
      name: "Url",
      header: "URL",
      selector: ({ row }) => <span>{row.original.Url || "-"}</span>,
    },
    {
      name: "Name",
      header: "NAMA",
    },
    {
      name: "Status",
      header: "STATUS",
      selector: (row) =>
        row.getValue() === 1 ? (
          <span className="badge badge-success">Aktif</span>
        ) : (
          <span className="badge badge-danger">Tidak Aktif</span>
        ),
    },
    {
      name: "Title",
      header: "JUDUL",
    },
    {
      name: "Description",
      header: "DESKRIPSI",
    },
  ];

  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12">
        <div className="card planned_task">
          <div className="header">
            <h2>Tabel Data</h2>
          </div>
          <div className="body">
            <DataTable data={dataMapLayers} column={jsonColumn} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTable;
