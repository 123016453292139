import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Combobox, TextArea } from "src/components/atoms";
import {
  actGetSdiAgencies,
  actGetSdiCategories,
  actGetSdiDatasetTypes,
  actGetSdiFieldTypes,
  actGetSdiPublicationDocumentTypes,
  actGetSdiTopics,
  actGetSdiYears,
} from "src/redux/actions";

const SectionDataset = ({ hook }) => {
  const dispatch = useDispatch();
  const {
    dataSdiAgencies,
    dataSdiPublicationDocumentTypes,
    dataSdiTopics,
    dataSdiCategories,
    dataSdiYears,
  } = useSelector((state) => state.sdiReducer);
  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = hook;

  useEffect(() => {
    dispatch(actGetSdiAgencies());
    dispatch(actGetSdiPublicationDocumentTypes());
    dispatch(actGetSdiTopics());
    dispatch(actGetSdiDatasetTypes());
    dispatch(actGetSdiFieldTypes());
    dispatch(actGetSdiYears());
  }, [dispatch]);

  useEffect(() => {
    setValue("agencyId", [
      {
        label: "DINAS KOMUNIKASI DAN INFORMATIKA",
        value: 10109,
      },
    ]);
    setValue("datasetPublicationDocumentTypeId", [
      {
        label: "Excel",
        value: 1,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSdiAgencies, dataSdiPublicationDocumentTypes]);

  return (
    <div className="row clearfix">
      <div className="col-lg-10 col-md-10 mx-auto">
        <div className="card planned_task">
          <div className="header">
            <h2>Form Dataset</h2>
          </div>
          <div className="body">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <TextArea
                    label={"Deskripsi"}
                    hook={register("description", { required: true })}
                    isError={errors.description}
                  />
                </div>
                <div className="col-md-4">
                  <Controller
                    control={control}
                    name={`agencyId`}
                    render={({ field }) => (
                      <Combobox
                        label={"Produsen Data"}
                        options={dataSdiAgencies}
                        values={field.value}
                        disabled={true}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        isError={errors.agencyId}
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                </div>

                {/* <div className="col-md-6">
                  <Controller
                    control={control}
                    name={`IdJenisSurvey`}
                    render={({ field }) => (
                      <Combobox
                        label={"Topik"}
                        options={jsonJenisSurvey}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                </div> */}
                <div className="col-md-2">
                  <Controller
                    control={control}
                    name={`datasetTopicId`}
                    render={({ field }) => (
                      <Combobox
                        label={"Topik"}
                        options={dataSdiTopics}
                        values={field.value}
                        onChange={(val) => {
                          field.onChange(val);
                          dispatch(actGetSdiCategories(val[0].value));
                        }}
                        isError={errors.datasetTopicId}
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                </div>
                <div className="col-md-4">
                  <Controller
                    control={control}
                    name={`datasetCategoryId`}
                    render={({ field }) => (
                      <Combobox
                        label={"Kategori/Group"}
                        options={dataSdiCategories}
                        values={field.value}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        isError={errors.datasetCategoryId}
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                </div>
                {/* <div className="col-md-6">
                  <Controller
                    control={control}
                    name={`datasetPublicationDocumentTypeId`}
                    render={({ field }) => (
                      <Combobox
                        label={"Tipe"}
                        options={dataSdiDatasetTypes}
                        values={field.value}
                        disabled={true}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                </div> */}
                <div className="col-md-2">
                  <Controller
                    control={control}
                    name={`year`}
                    render={({ field }) => (
                      <Combobox
                        label={"Tahun"}
                        options={dataSdiYears}
                        values={field.value}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        disabled={true}
                        isError={errors.year}
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                </div>
                {/* <div className="col-md-6">
                  <Controller
                    control={control}
                    name={`FileUpload`}
                    render={({ field }) => (
                      <TextUpload
                        label={"File Excel"}
                        name={field.name}
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                </div> */}
                {/* <div className="col-md-6">
                  <Controller
                    control={control}
                    name={`FileUpload`}
                    render={({ field }) => (
                      <TextUpload
                        label={"File Approve (PDF)"}
                        name={field.name}
                        // fileSelect={
                        //   getValues(`Files[${index}]`)?.FileUpload?.name
                        // }
                        // onChange={(val) => {
                        //   onFile(val);
                        // }}
                        // onDel={() => setValue(`Files[${index}]`, undefined)}
                        // isError={errors?.Files?.[index]?.FileUpload}
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionDataset;
