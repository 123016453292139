import React from "react";
import { LayoutClient } from "src/components/molecules";
import SectionForm from "./parts/SectionForm";
import { useParams } from "react-router-dom";

const LayerEdit = () => {
  let params = useParams();

  return (
    <LayoutClient
      page="Perbarui Map Grup"
      breadcrumb={[
        { name: "Data Map Grup", navigate: "/layergrup" },
        {
          name: "Data Map Layer",
          navigate: `/layergrup/layer/${params.groupid}`,
        },
        { name: "Page", navigate: "" },
      ]}
    >
      <SectionForm />
    </LayoutClient>
  );
};

export default LayerEdit;
