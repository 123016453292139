import React, { useEffect, useRef, useState } from "react";
import { loadModules } from "esri-loader";
import { Spinner } from "src/components/atoms";
import { useSelector, useDispatch } from "react-redux";
import { FUNCConvertArrayToGroup } from "src/utils";
import "./styles.css";
import { actGetMap } from "src/redux/actions";

export const MapContainerDragPoint = ({ hookForm, disableDrag = false }) => {
  const { setValue, getValues } = hookForm;

  const { dataGetMap } = useSelector((state) => state.homeReducer);
  const [isLoad, setIsLoad] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actGetMap());
  }, [dispatch]);

  const mapRef = useRef();
  const options = {
    url: "https://js.arcgis.com/4.27/",
    css: true,
  };
  useEffect(() => {
    if (!getValues("longitudeUpdate")) {
      setValue("longitudeUpdate", dataGetMap?.CenterLongitude);
      setValue("latitudeUpdate", dataGetMap?.CenterLatitude);
    } else {
      setValue("longitudeUpdate", getValues("longitude"));
      setValue("latitudeUpdate", getValues("latitude"));
    }
    setTimeout(() => {
      setIsLoad(!isLoad);
      if (dataGetMap) onMapview();
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGetMap]);

  const onMapview = async () => {
    let view;
    await loadModules(
      [
        "esri/Map",
        "esri/views/MapView",
        "esri/widgets/BasemapGallery",
        "esri/widgets/LayerList",
        "esri/widgets/Legend",
        "esri/widgets/Expand",
        "esri/widgets/ScaleBar",
        "esri/widgets/Fullscreen",
        "esri/layers/WMSLayer",
        "esri/widgets/Sketch",
        "esri/layers/GraphicsLayer",
        "esri/views/interactive/snapping/SnappingOptions",
        "esri/layers/WFSLayer",
        "esri/layers/TileLayer",
        "esri/views/SceneView",
        "esri/Basemap",
        "esri/geometry/Point",
        "esri/layers/GeoJSONLayer",
        "esri/widgets/Measurement",
        "esri/widgets/Print",
        "esri/widgets/Search",
        "esri/Graphic",
        "esri/core/reactiveUtils",
        "esri/core/promiseUtils",
        "esri/layers/GroupLayer",
        "esri/symbols/PictureMarkerSymbol",
        "esri/widgets/Sketch/SketchViewModel",
        "esri/geometry/support/webMercatorUtils",
      ],
      options
    ).then(
      async ([
        Map,
        MapView,
        BasemapGallery,
        LayerList,
        Legend,
        Expand,
        ScaleBar,
        Fullscreen,
        WMSLayer,
        Sketch,
        GraphicsLayer,
        SnappingOptions,
        WFSLayer,
        TileLayer,
        SceneView,
        Basemap,
        Point,
        GeoJSONLayer,
        Measurement,
        Print,
        Search,
        Graphic,
        reactiveUtils,
        promiseUtils,
        GroupLayer,
        PictureMarkerSymbol,
        SketchViewModel,
        webMercatorUtils,
      ]) => {
        /********************
         * Create image layer GEOSERVER
         ********************/
        var layersAlls = [];
        var layersToMaps = [];
        var basemaps = [];

        var dataBasemap = dataGetMap?.LayerGroups?.filter(
          (x) => x.Name === "BASEMAP"
        )[0];

        dataBasemap?.Layers?.forEach((item) => {
          if (item.Url) {
            const layerBasemap = new WMSLayer({
              url: item.Url,
              sublayers: [
                {
                  title: item.Title,
                  name: item.Name, //bogor_spasial:data_citra
                },
              ],
              popupEnabled: false,
            });
            var basemapCustom = new Basemap({
              baseLayers: [layerBasemap],
              title: item.Title,
              id: item.Groupid,
              thumbnailUrl: item.Thumbnail,
            });
            basemaps.push(basemapCustom);
          } else {
            basemaps.push(Basemap.fromId(item.Name));
          }
        });

        layersAlls = FUNCConvertArrayToGroup(layersAlls);

        layersAlls.forEach((item) => {
          const itemlayer = new GroupLayer({
            title: item.name,
            visible: true,
            layers: item.layers,
            opacity: 0.75,
          });
          layersToMaps.push(itemlayer);
        });

        const graphicsLayer = new GraphicsLayer({
          id: "tempGraphics",
          title: "Buat sendiri",
        });

        const map = new Map({
          basemap: "topo-vector",
          layers: [graphicsLayer],
        });

        view = new MapView({
          container: mapRef.current,
          map: map,
          center: [getValues("longitudeUpdate"), getValues("latitudeUpdate")],
          zoom: 15,
          popup: {
            defaultPopupTemplateEnabled: true, // popup will be enabled on the wfslayer
          },
        });

        var point = {
          type: "point",
          longitude: getValues("longitudeUpdate"),
          latitude: getValues("latitudeUpdate"),
        };

        var markerSymbol = {
          type: "picture-marker",
          url: "https://static.arcgis.com/images/Symbols/Shapes/BluePin1LargeB.png",
          width: "60px",
          height: "60px",
        };

        var pointGraphic = new Graphic({
          geometry: webMercatorUtils.geographicToWebMercator(point),
          symbol: markerSymbol,
        });

        /********************
         * Extension esri
         ********************/

        const scaleBarWidget = new ScaleBar({
          view: view,
          unit: "dual",
        });

        const searchWidget = new Search({
          view: view,
        });
        const bgExpand = new Expand({
          view: view,
          content: new BasemapGallery({
            view: view,
            showArcGISBasemaps: true,
            headingLevel: 3,
            source: basemaps,
          }),
          expandTooltip: "Show Basemap",
          expandIconClass: "esri-icon-basemap",
          group: "top-right",
        });

        if (!disableDrag)
          view.on("click", function (event) {
            var lat = event.mapPoint.latitude;
            var lon = event.mapPoint.longitude;

            view.center = [lon, lat];
            graphicsLayer.removeAll();
            let pt = new Point({
              latitude: lat,
              longitude: lon,
            });
            view.goTo(
              {
                target: pt,
                zoom: 18,
              },
              {
                duration: 1000,
              }
            );
            graphicsLayer.add(
              new Graphic({
                geometry: pt,
                symbol: markerSymbol,
              })
            );

            setValue("latitudeUpdate", lat);
            setValue("longitudeUpdate", lon);
          });

        view.when(() => {
          graphicsLayer.graphics.add(pointGraphic);
          view.ui.add([searchWidget, bgExpand], "top-right");
          view.ui.add([scaleBarWidget], "bottom-left");
        });
      }
    );
    return () => {
      if (!!view) {
        view.destroy();
        view = null;
      }
    };
  };

  return (
    <div className="w-100 h-100">
      <Spinner loading={isLoad} />
      <div className="p-0 m-0 h-100 w-100" ref={mapRef} />
    </div>
  );
};
