import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Combobox, TextArea, TextInput } from "src/components/atoms";
import {
  actGetJenisSurveys,
  actGetKuisioners,
  actGetSdiFrequencies,
  actGetSdiLicenses,
  actGetSdiPeriod,
  actGetSdiPublicationDatasetTypes,
  actGetSdiScopes,
  actGetSdiTags,
  actGetAttributKuisioner,
  actGetTemplateSurvey,
  actTagAdd,
} from "src/redux/actions";

const SectionMetadata = ({ hook }) => {
  let { customid } = useParams();
  const dispatch = useDispatch();
  const [showModalTag, setShowModalTag] = useState(false);

  const {
    dataSdiJenisSurveys,
    dataSdiJenisKuisioners,
    dataSdiPublicationDatasetTypes,
    dataSdiLicenses,
    dataSdiScopes,
    dataSdiFrequencies,
    dataSdiPeriod,
    dataSdiTags,
  } = useSelector((state) => state.sdiReducer);
  const {
    register,
    getValues,
    formState: { errors },
    control,
    setValue,
    resetField,
  } = hook;
  const {
    register: registerTag,
    handleSubmit: handleSubmitTag,
    setValue: setValueTag,
    formState: { errors: errorsTag },
  } = useForm();

  useEffect(() => {
    if (customid === "survey") dispatch(actGetJenisSurveys());
    if (customid === "kuisioner") dispatch(actGetKuisioners());
    dispatch(actGetSdiPublicationDatasetTypes());
    dispatch(actGetSdiLicenses());
    dispatch(actGetSdiScopes());
    dispatch(actGetSdiFrequencies());
    dispatch(actGetSdiTags());
  }, [dispatch, customid]);

  const onModalTag = () => {
    setValueTag("name", "");
    setShowModalTag(!showModalTag);
  };

  const onJenisSurvey = (val) => {
    val = val[0];
    // let txtTest = `- TEST RANDOM [${new Date().getMilliseconds()}]`; //DELETE HERE
    let txtTest = ``; //DELETE HERE
    let title = `${val.label.replace(/\s\d{4}\b/g, "")} ${txtTest}`;
    if (customid === "survey") {
      dispatch(actGetTemplateSurvey(val.value));
      title = `${val?.RefJenisSurvey} ${txtTest}`;
    }
    if (customid === "kuisioner") {
      dispatch(actGetAttributKuisioner(val.value));
      title = `${val?.RefKuisioner} ${txtTest}`;
    }

    setValue("title", title, {
      shouldValidate: true,
    });
    setValue("year", [
      {
        label: val.Tahun,
        value: val.Tahun,
      },
    ]);
    resetField("attributes"); //reset
  };

  const btnSaveTag = (data) => {
    dispatch(actTagAdd(data.name, onModalTag));
  };

  const ComboDynamicWithParam = () => {
    if (customid === "survey")
      return (
        <Controller
          control={control}
          name={`jenisSurveyId`}
          render={({ field }) => (
            <Combobox
              label={"Pilih Jenis Survey"}
              options={dataSdiJenisSurveys}
              values={field.value}
              onChange={(val) => {
                field.onChange(val);
                onJenisSurvey(val);
              }}
              isError={errors.jenisSurveyId}
            />
          )}
          rules={{
            required: true,
          }}
        />
      );

    if (customid === "kuisioner")
      return (
        <Controller
          control={control}
          name={`jenisSurveyId`}
          render={({ field }) => (
            <Combobox
              label={"Pilih Jenis Kuisioner"}
              options={dataSdiJenisKuisioners}
              values={field.value}
              onChange={(val) => {
                field.onChange(val);
                onJenisSurvey(val);
              }}
              isError={errors.jenisSurveyId}
            />
          )}
          rules={{
            required: true,
          }}
        />
      );
    return <></>;
  };

  return (
    <>
      <div className="row clearfix">
        <div className="col-lg-10 col-md-10 mx-auto">
          <div className="card planned_task">
            <div className="header">
              <h2>Form Metadata</h2>
            </div>
            <div className="body">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <ComboDynamicWithParam />
                  </div>
                  <div className="col-md-12">
                    <TextArea
                      label={"Judul"}
                      hook={register("title", { required: true })}
                      readOnly
                      isError={errors.title}
                    />
                  </div>
                  <div className="col-md-3">
                    <Controller
                      control={control}
                      name={`publicationType`}
                      render={({ field }) => (
                        <Combobox
                          label={"Publikasi"}
                          options={dataSdiPublicationDatasetTypes}
                          values={field.value}
                          onChange={(val) => {
                            field.onChange(val);
                          }}
                          isError={errors.publicationType}
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <Controller
                      control={control}
                      name={`licenseId`}
                      render={({ field }) => (
                        <Combobox
                          label={"Lisensi"}
                          options={dataSdiLicenses}
                          values={field.value}
                          onChange={(val) => {
                            field.onChange(val);
                          }}
                          isError={errors.licenseId}
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <Controller
                      control={control}
                      name={`dataScopeId`}
                      render={({ field }) => (
                        <Combobox
                          label={"Cakupan"}
                          options={dataSdiScopes}
                          values={field.value}
                          onChange={(val) => {
                            field.onChange(val);
                          }}
                          isError={errors.dataScopeId}
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <TextInput
                      label={"Sumber"}
                      hook={register("dataSource", { required: true })}
                      isError={errors.dataSource}
                    />
                  </div>
                  <div
                    className={` ${
                      getValues()?.frequencyId?.[0]?.value !== 1
                        ? "col-md-3"
                        : "col-md-6"
                    }`}
                  >
                    <Controller
                      control={control}
                      name={`frequencyId`}
                      render={({ field }) => (
                        <Combobox
                          label={"Frekuensi"}
                          options={dataSdiFrequencies}
                          values={field.value}
                          onChange={(val) => {
                            field.onChange(val);
                            dispatch(actGetSdiPeriod(val[0].label));
                            setValue("period", []);
                          }}
                          isError={errors.frequencyId}
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                    />
                  </div>
                  {getValues()?.frequencyId?.[0]?.value !== 1 && (
                    <div className="col-md-3">
                      <Controller
                        control={control}
                        name={`period`}
                        render={({ field }) => (
                          <Combobox
                            label={"Periode"}
                            options={dataSdiPeriod}
                            values={field.value}
                            onChange={(val) => {
                              field.onChange(val);
                            }}
                            isError={errors.period}
                          />
                        )}
                        rules={{
                          required: getValues()?.frequencyId?.[0]?.value !== 1,
                        }}
                      />
                    </div>
                  )}

                  <div className="col-md-12">
                    <Controller
                      control={control}
                      name={`tagIds`}
                      render={({ field }) => (
                        <Combobox
                          label={"Tag"}
                          options={dataSdiTags}
                          values={field.value}
                          multi={true}
                          onChange={(val) => {
                            field.onChange(val);
                          }}
                          isError={errors.tagIds}
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                    />
                    <p className="text-sm">
                      Tambah Tag Baru ?{" "}
                      <span className="text-primary" onClick={onModalTag}>
                        Buat
                      </span>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* MODEL */}
      <Modal size={"sm"} centered show={showModalTag} onHide={onModalTag}>
        <Modal.Header closeButton>
          <Modal.Title>Tambah Tag Baru</Modal.Title>
        </Modal.Header>

        <form>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <TextInput
                  label={"Nama"}
                  hook={registerTag("name", { required: true })}
                  isError={errorsTag.name}
                />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button type="button" className="btn btn-dark" onClick={onModalTag}>
              <i className="fa fa-close"></i> <span>Tutup</span>
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleSubmitTag(btnSaveTag)}
            >
              <i className="fa fa-save"></i> <span>Simpan Data</span>
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default SectionMetadata;
