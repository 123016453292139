import { MapService } from "src/services";
import { FUNCHandleRequest, loadingBar } from "src/utils";
import Swal from "sweetalert2/dist/sweetalert2";

export const actGetLayerGroups = () => {
  return (dispatch) => {
    dispatch({ type: "DATA_MAP_LAYER_GROUPS", payload: [] });
    FUNCHandleRequest(() =>
      MapService.getLayerGroups().then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_MAP_LAYER_GROUPS", payload: data.Data });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetLayerGroup = (id, history = null) => {
  return (dispatch) => {
    dispatch({ type: "DATA_MAP_LAYER_GROUP", payload: null });
    FUNCHandleRequest(() =>
      MapService.getLayerGroup(id).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_MAP_LAYER_GROUP", payload: data.Data });
          if (history) history.push(`/layergrup/edit/${id}`);
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actAddLayerGroup = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();
    fd.append("name", iData.name);
    fd.append("description", iData.description);
    MapService.addLayerGroup(fd).then((res) => {
      loadingBar(false);
      var data = res.data;
      if (data.IsSuccess) {
        Swal.fire("Berhasil", "Berhasil tambah data!", "success").then(
          () => (window.location = "/layergrup")
        );
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const actEditLayerGroup = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();
    fd.append("id", iData.id);
    fd.append("name", iData.name);
    fd.append("description", iData.description);
    MapService.editLayerGroup(fd).then((res) => {
      loadingBar(false);
      var data = res.data;
      if (data.IsSuccess) {
        Swal.fire("Berhasil", "Berhasil perbarui data!", "success").then(
          () => (window.location = "/layergrup")
        );
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const actDeleteLayerGroup = (id) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan hapus grup ini!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        var fd = new FormData();
        fd.append("id", id);
        FUNCHandleRequest(() =>
          MapService.deleteLayerGroup(fd).then((res) => {
            var data = res.data;
            if (data?.IsSuccess) {
              Swal.fire(
                "Berhasil",
                "Layer grup berhasil dihapus!",
                "success"
              ).then(() => dispatch(actGetLayerGroups()));
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          })
        );
      }
    });
  };
};

export const actGetLayers = (groupId, history = null) => {
  return (dispatch) => {
    if (history) history.push(`/layergrup/layer/${groupId}`);
    dispatch({ type: "DATA_MAP_LAYERS", payload: [] });
    FUNCHandleRequest(() =>
      MapService.getLayers(groupId).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_MAP_LAYERS", payload: data.Data });
        } else {
          // Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetLayer = (id, groupid, history = null) => {
  return (dispatch) => {
    dispatch({ type: "DATA_MAP_LAYER", payload: null });
    FUNCHandleRequest(() =>
      MapService.getLayer(id).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_MAP_LAYER", payload: data.Data });
          if (history) history.push(`/layergrup/layer/${groupid}/edit/${id}`);
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actAddLayer = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();
    fd.append("groupId", iData.groupId);
    fd.append("type", iData.type[0]?.value);
    fd.append("url", iData.url);
    fd.append("name", iData.name);
    fd.append("title", iData.title);
    fd.append("description", iData.description);
    fd.append("status", iData.status[0]?.value);
    MapService.addLayer(fd).then((res) => {
      loadingBar(false);
      var data = res.data;
      if (data.IsSuccess) {
        Swal.fire("Berhasil", "Berhasil tambah data!", "success").then(
          () => (window.location = `/layergrup/layer/${iData.groupId}`)
        );
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const actEditLayer = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();
    fd.append("id", iData.id);
    fd.append("groupid", iData.groupid);
    fd.append("type", iData.type[0]?.value);
    fd.append("url", iData.url || "");
    fd.append("name", iData.name);
    fd.append("title", iData.title);
    fd.append("description", iData.description);
    fd.append("status", iData.status[0]?.value);
    MapService.editLayer(fd).then((res) => {
      loadingBar(false);
      var data = res.data;
      if (data.IsSuccess) {
        Swal.fire("Berhasil", "Berhasil perbarui data!", "success").then(
          () => (window.location = `/layergrup/layer/${iData.groupid}`)
        );
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const actDeleteLayer = (id, groupId) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan hapus layer ini!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        var fd = new FormData();
        fd.append("id", id);
        FUNCHandleRequest(() =>
          MapService.deleteLayer(fd).then((res) => {
            var data = res.data;
            if (data?.IsSuccess) {
              Swal.fire("Berhasil", "Layer berhasil dihapus!", "success").then(
                () => dispatch(actGetLayers(groupId))
              );
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          })
        );
      }
    });
  };
};
