/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import { loadModules } from "esri-loader";
import { Spinner } from "src/components/atoms";
import { useSelector, useDispatch } from "react-redux";
import {
  ARCClusterConfig,
  ARCPopupContent,
  FUNCConvertArrayToGroup,
} from "src/utils";
import "./styles.css";
import { actGetMap } from "src/redux/actions";

export const MapContainer = () => {
  const dataGetMap = useSelector((state) => state.homeReducer.dataGetMap);
  const [isLoad, setIsLoad] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actGetMap());
  }, [dispatch]);

  const mapRef = useRef();
  const options = {
    url: "https://js.arcgis.com/4.27/",
    css: true,
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoad(!isLoad);
      if (dataGetMap) onMapview();
    }, 2000);
  }, [dataGetMap]);

  const onMapview = async () => {
    let view;
    await loadModules(
      [
        "esri/Map",
        "esri/views/MapView",
        "esri/widgets/BasemapGallery",
        "esri/widgets/LayerList",
        "esri/widgets/Legend",
        "esri/widgets/Expand",
        "esri/widgets/ScaleBar",
        "esri/widgets/Fullscreen",
        "esri/layers/WMSLayer",
        "esri/widgets/Sketch",
        "esri/layers/GraphicsLayer",
        "esri/views/interactive/snapping/SnappingOptions",
        "esri/layers/WFSLayer",
        "esri/layers/TileLayer",
        "esri/views/SceneView",
        "esri/Basemap",
        "esri/geometry/Point",
        "esri/layers/GeoJSONLayer",
        "esri/widgets/Measurement",
        "esri/widgets/Print",
        "esri/widgets/Search",
        "esri/Graphic",
        "esri/core/reactiveUtils",
        "esri/core/promiseUtils",
        "esri/layers/GroupLayer",
      ],
      options
    ).then(
      async ([
        Map,
        MapView,
        BasemapGallery,
        LayerList,
        Legend,
        Expand,
        ScaleBar,
        Fullscreen,
        WMSLayer,
        Sketch,
        GraphicsLayer,
        SnappingOptions,
        WFSLayer,
        TileLayer,
        SceneView,
        Basemap,
        Point,
        GeoJSONLayer,
        Measurement,
        Print,
        Search,
        Graphic,
        reactiveUtils,
        promiseUtils,
        GroupLayer,
      ]) => {
        /********************
         * Create image layer GEOSERVER
         ********************/
        var layersAlls = [];
        var layersToMaps = [];
        var basemaps = [];

        var dataBasemap = dataGetMap?.LayerGroups?.filter(
          (x) => x.Name === "BASEMAP"
        )[0];
        var dataLayers = dataGetMap?.LayerGroups?.filter(
          (x) => x.Name !== "BASEMAP"
        );

        dataLayers?.map((layer) => {
          layer?.Layers?.map((item) => {
            var uniqueValueInfos = [];
            if (item.Legend)
              item.Legend.Conditions.map((item) => {
                uniqueValueInfos.push({
                  value: item.Value,
                  symbol: {
                    type: "picture-marker",
                    url: item.Symbol,
                  },
                  label: item.Label,
                });
              });

            const jsonWfs = {
              url: item.Url,
              name: item.Name,
              title: item.Title,
              outFields: ["*"],
              popupTemplate: {
                title: (feature) => `Layer: ${item.Title}`,
                content: (feature) => ARCPopupContent(feature),
              },
            };

            if (item.mapLayer === "point") {
              Object.assign(jsonWfs, {
                featureReduction: item.mapCluster
                  ? ARCClusterConfig(item.titleLayer, item.descriptionCluster)
                  : null,
                renderer: {
                  type: "unique-value",
                  field: item.legend.field,
                  defaultSymbol: {
                    type: "picture-marker",
                    url: item.legend.defaultSymbol,
                  },
                  uniqueValueInfos,
                },
              });
            }

            var layerWfs = new WFSLayer(jsonWfs);
            layersAlls.push({
              name: layer.Name,
              layer: layerWfs,
            });
          });
        });

        dataBasemap?.Layers?.map((item) => {
          if (item.Url) {
            const layerBasemap = new WMSLayer({
              url: item.Url,
              sublayers: [
                {
                  title: item.Title,
                  name: item.Name, //bogor_spasial:data_citra
                },
              ],
              popupEnabled: false,
            });
            var basemapCustom = new Basemap({
              baseLayers: [layerBasemap],
              title: item.Title,
              id: item.Groupid,
              thumbnailUrl: item.Thumbnail,
            });
            basemaps.push(basemapCustom);
          } else {
            basemaps.push(Basemap.fromId(item.Name));
          }
        });

        layersAlls = FUNCConvertArrayToGroup(layersAlls);

        layersAlls.map((item) => {
          const itemlayer = new GroupLayer({
            title: item.name,
            visible: true,
            layers: item.layers,
            opacity: 0.75,
          });
          layersToMaps.push(itemlayer);
        });

        const map = new Map({
          basemap: "topo-vector",
          layers: layersToMaps,
        });

        view = new MapView({
          container: mapRef.current,
          map: map,
          // center: [JSONLayer.center.longitude, JSONLayer.center.latitude],
          center: [dataGetMap.CenterLongitude, dataGetMap.CenterLatitude],
          zoom: dataGetMap.ZoomLevel,
          popup: {
            defaultPopupTemplateEnabled: true, // popup will be enabled on the wfslayer
          },
        });

        /********************
         * Extension esri
         ********************/

        const fullscreenWidget = new Fullscreen({
          view: view,
        });

        const graphicsWidget = new GraphicsLayer({
          title: "Buat sendiri",
        });

        const scaleBarWidget = new ScaleBar({
          view: view,
          unit: "dual",
        });

        const searchWidget = new Search({
          view: view,
        });

        const bgExpand = new Expand({
          view: view,
          content: new BasemapGallery({
            view: view,
            showArcGISBasemaps: true,
            headingLevel: 3,
            source: basemaps,
          }),
          expandTooltip: "Show Basemap",
          expandIconClass: "esri-icon-basemap",
          group: "top-right",
        });

        const layerExpand = new Expand({
          view: view,
          content: new LayerList({
            view,
          }),
          expandTooltip: "Show Layer",
          expandIconClass: "esri-icon-layers",
          group: "top-right",
        });

        const legendExpand = new Expand({
          view: view,
          content: new Legend({
            view: view,
          }),
          expandTooltip: "Show Layer",
          expandIconClass: "esri-icon-legend",
          group: "top-left",
        });

        const sketchExpand = new Expand({
          view: view,
          content: new Sketch({
            layer: graphicsWidget,
            view: view,
            creationMode: "update",
            snappingOptions: {
              enabled: true,
              featureSources: [
                { layer: graphicsWidget, enabled: true },
                // { layer: layerWfs, enabled: true },
              ],
            },
          }),
          expandTooltip: "Show Layer",
          expandIconClass: "esri-icon-sketch-rectangle",
          group: "top-right",
        });

        const printExpand = new Expand({
          view: view,
          content: new Print({
            view: view,
            printServiceUrl:
              "https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task",
          }),
          expandTooltip: "Show Tools",
          expandIconClass: "esri-icon-download",
          group: "top-right",
        });

        map.add(graphicsWidget);

        view.when(() => {
          view.ui.add(
            [
              searchWidget,
              sketchExpand,
              fullscreenWidget,
              bgExpand,
              layerExpand,
              printExpand,
            ],
            "top-right"
          );
          view.ui.add([legendExpand], "top-left");
          view.ui.add([scaleBarWidget], "bottom-left");
        });
      }
    );
    return () => {
      if (!!view) {
        view.destroy();
        view = null;
      }
    };
  };
  return (
    <div className="w-100 h-100">
      <Spinner loading={isLoad} />
      <div className="p-0 m-0 h-100 w-100" ref={mapRef} />
    </div>
  );
};
