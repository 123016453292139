import React from "react";
import { LayoutClient } from "src/components/molecules";
import SectionForm from "./parts/SectionForm";

const LayerGroupEdit = () => {
  return (
    <LayoutClient
      page="Perbarui Map Grup"
      breadcrumb={[
        { name: "Data Map Grup", navigate: "/layergrup" },
        { name: "Page", navigate: "" },
      ]}
    >
      <SectionForm />
    </LayoutClient>
  );
};

export default LayerGroupEdit;
