import { FUNCIsImage } from "../function";

export const ARCClusterConfig = (title, content) => {
  return {
    type: "cluster",
    clusterRadius: "100px",
    popupTemplate: {
      title,
      content,
      fieldInfos: [
        {
          fieldName: "cluster_count",
          format: {
            places: 0,
            digitSeparator: true,
          },
        },
      ],
    },
    clusterMinSize: "24px",
    clusterMaxSize: "60px",

    symbol: {
      type: "simple-marker",
      style: "circle",
      color: "#49c5b6",
      outline: {
        color: "rgba(41, 209, 149, 0.4)",
        width: 6,
      },
    },

    labelingInfo: [
      {
        deconflictionStrategy: "none",
        labelExpressionInfo: {
          expression: "Text($feature.cluster_count, '#,###')",
        },
        symbol: {
          type: "text",
          color: "#fff",
          font: {
            weight: "bold",
            family: "Noto Sans",
            size: "12px",
          },
        },
        labelPlacement: "center-center",
      },
    ],
  };
};

export const ARCGeoJSONLayer = (GeoJSONLayer) => {
  return new GeoJSONLayer({
    title: "Earthquakes from the last month",
    url: "https://earthquake.usgs.gov/earthquakes/feed/v1.0/summary/all_month.geojson",
    copyright: "USGS Earthquakes",
    outFields: ["*"],
    featureReduction: ARCClusterConfig("Judul Geox", "Desc x"),
    renderer: {
      type: "unique-value",
      field: "status",
      uniqueValueInfos: [
        {
          value: "reviewed",
          symbol: {
            type: "picture-marker",
            url: "https://static.arcgis.com/images/Symbols/Arrows/icon134.png",
          },
        },
        {
          value: "automatic",
          symbol: {
            type: "picture-marker",
            url: "https://static.arcgis.com/images/Symbols/Arrows/icon130.png",
          },
        },
      ],
      defaultSymbol: {
        type: "picture-marker",
        url: "https://static.arcgis.com/images/Symbols/Arrows/icon137.png",
      },
      visualVariables: [
        {
          type: "size",
          field: "mag",
          stops: [
            {
              value: 2.5,
              size: "24px",
            },
            {
              value: 8,
              size: "60px",
            },
          ],
        },
      ],
    },
  });
};

export const ARCPopupContent = (feature) => {
  var html,
    div = document.createElement("div");
  div.className = "myClass";
  html = `
    <table cellspacing="1" cellpadding="2">
  `;
  Object.keys(feature.graphic.attributes).map((v, i) => {
    var label = v.replace(/_/g, " ").toUpperCase();
    var value = feature.graphic.attributes[v] || "tidak ada";
    if (label === "ID")
      value = `<span class="badge badge-primary">${value}</span>`;

    var isImage = FUNCIsImage(value);
    if (isImage) {
      value = `
      <a href="https://awsimages.detik.net.id/community/media/visual/2022/01/26/kolam-renang-plaza-marina_169.jpeg?w=1200" target="_blank">
      <img
      className="img-fluid img-thumbnail"
      src="https://awsimages.detik.net.id/community/media/visual/2022/01/26/kolam-renang-plaza-marina_169.jpeg?w=1200"
      alt="Awesome Image"
      onerror="this.onerror=null; this.src='/img-empty.jpg'"
      style="object-fit:cover"
      width="100px"
      height="100px"
      />
      </a>
      `;
    }

    if (label !== "  ESRI WFS ID  ")
      html += `
  			<tr>
          <td class="pb-3" width="200px"><strong>${label}:</strong></td>
          <td class="pb-3">${value}</td>
        </tr>`;
  });
  html += `
  			<tr>
          <td class="pb-3" width="200px"><strong>VIDEO:</strong></td>
          <td class="pb-3">
            <div class="video-container">
              <video controls>
                <source src="https://regionalinvestment.bkpm.go.id/pir/pir_video.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
                Your browser does not support the video tag.
              </video>
            </div>
          </td>
        </tr>`;

  html += `
    </table>
  `;
  div.innerHTML = html;
  return div;
};

export const ARCOverviewMap = (
  promiseUtils,
  view,
  view2,
  Graphic,
  reactiveUtils
) => {
  const extentDebouncer = promiseUtils.debounce(async () => {
    if (view.stationary) {
      await view2.goTo({
        center: view.center,
        scale:
          view.scale *
          2 *
          Math.max(view.width / view2.width, view.height / view2.height),
      });
    }
  });

  const extent3Dgraphic = new Graphic({
    geometry: null,
    symbol: {
      type: "simple-fill",
      color: [0, 0, 0, 0.5],
      outline: null,
    },
  });
  view2.graphics.add(extent3Dgraphic);

  reactiveUtils.watch(
    () => view.extent,
    (extent) => {
      extentDebouncer().then(() => {
        extent3Dgraphic.geometry = extent;
      });
    },
    {
      initial: true,
    }
  );
};
