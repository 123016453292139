import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actFormSurvey,
  actGetJenisSurveyById,
  actGetSurvey,
  actGetWilReferences,
} from "src/redux/actions";
import { useParams, useHistory } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Combobox, TextArea, TextInput } from "src/components/atoms";
import { tipeComboWilDynamic, tipeInputSurvey } from "src/utils";
import { MapContainerDragPoint } from "src/components/organisms";
import { IMGUserEmpty } from "src/assets/images/dummy";

const SectionView = () => {
  const { customid, id } = useParams();
  const history = useHistory();

  let jenisSurveyId = customid;
  let surveyId = id;

  const dispatch = useDispatch();
  const { dataJenisSurveyById } = useSelector((state) => state.refReducer);
  const { dataSurvey } = useSelector((state) => state.surveyReducer);
  const { dataWilReferences } = useSelector((state) => state.wilReducer);

  const hookForm = useForm();
  const {
    register,
    formState: { errors },
    control,
    setValue,
    reset,
  } = hookForm;

  const { fields: fieldsAtributs, append: appendAtributs } = useFieldArray({
    control,
    name: "Atributs",
  });
  const { fields: fieldsFiles, append: appendFiles } = useFieldArray({
    control,
    name: "Files",
  });

  useEffect(() => {
    reset();
    dispatch(actGetJenisSurveyById(jenisSurveyId));
    dispatch(actGetSurvey(surveyId));
    dispatch(actGetWilReferences());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, jenisSurveyId, surveyId]);

  useEffect(() => {
    reset();
    setValue("kodeData", dataSurvey?.KodeData);
    setValue("latitude", dataSurvey?.Latitude);
    setValue("longitude", dataSurvey?.Longitude);
    setValue("latitudeUpdate", dataSurvey?.Latitude);
    setValue("longitudeUpdate", dataSurvey?.Longitude);
    dataSurvey?.Atributs?.forEach((item) => {
      if (item.Atribut?.Reftablesid) {
        let dataCombos = [];
        if (item.Atribut?.RefTables?.Istable) {
          dataWilReferences[item.Atribut.Reftablesid]?.forEach((item) => {
            dataCombos.push({
              value: item.Kode,
              label: item.Nama,
            });
          });
          appendAtributs({
            ...item,
            Jawabanori: item,
            StringValue: dataCombos.filter((x) => x.value == item.StringValue),
          });
        } else {
          item.Atribut.RefTables?.Info?.forEach((item) => {
            dataCombos.push({
              value: item.Code,
              label: item.Name,
            });
          });
          appendAtributs({
            ...item,
            Jawabanori: item,
            StringValue: dataCombos.filter((x) => x.value == item.StringValue),
          });
        }
      } else {
        appendAtributs({ ...item, Jawabanori: item });
      }
    });
    dataSurvey?.Files?.forEach((item) => {
      appendFiles(item);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSurvey]);

  return (
    <>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <div className="card planned_task">
            <div className="header">
              <h2>Data Survey {dataJenisSurveyById?.Namapendek}</h2>
            </div>
            <div className="body">
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <TextInput
                      label={dataJenisSurveyById?.Ketkodedata?.toUpperCase()}
                      hook={register("kodeData", { required: true })}
                      isError={errors.kodeData}
                      disabled={true}
                    />
                    <div className="row">
                      <div className="col-md-6">
                        <TextInput
                          label={"LONGITUDE"}
                          hook={register("longitude", { required: true })}
                          isError={errors.longitude}
                          disabled={true}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextInput
                          label={"LATITUDE"}
                          hook={register("latitude", { required: true })}
                          isError={errors.latitude}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <ContainerDynamicFormInput
                      data={fieldsAtributs}
                      hookForm={hookForm}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>DATA FILE</label>
                    <ContainerDynamicFormUpload
                      data={fieldsFiles}
                      hookForm={hookForm}
                    />
                    <div style={{ height: "60vh", position: "relative" }}>
                      <label>MAP LOKASI</label>
                      <MapContainerDragPoint
                        hookForm={hookForm}
                        disableDrag={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 d-flex justify-content-end mt-5">
                  <button
                    type="button"
                    className="btn btn-dark mr-2"
                    onClick={() => history.goBack()}
                  >
                    <i className="fa fa-arrow-left"></i> <span>Kembali</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ContainerDynamicFormInput = ({ data, hookForm }) => {
  return (
    <div className="row">
      {data?.map((item, index) => {
        return (
          <SurveyFormInput
            key={index}
            index={index}
            data={item}
            attribut={item.Atribut}
            hookForm={hookForm}
          />
        );
      })}
    </div>
  );
};

const SurveyFormInput = ({ index, data, attribut, hookForm }) => {
  const [dataPilihans, setDataPilihans] = useState([]);

  const { dataWilReferences } = useSelector((state) => state.wilReducer);
  const formSurvey = useSelector((state) => state.surveyReducer.formSurvey);
  const dispatch = useDispatch();
  const {
    register,
    control,
    formState: { errors },
    getValues,
  } = hookForm;
  const getValue = getValues();

  useEffect(() => {
    const arrdataPilihans = [];
    if (attribut.RefTables?.Istable) {
      dataWilReferences[attribut.Reftablesid]?.forEach((item) => {
        arrdataPilihans.push({
          ...item,
          value: item.Kode,
          label: item.Nama,
        });
      });
    } else {
      attribut.RefTables?.Info?.forEach((item) => {
        arrdataPilihans.push({
          ...item,
          value: item.Code,
          label: item.Name,
        });
      });
    }
    setDataPilihans(arrdataPilihans);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attribut]);

  useEffect(() => {
    if (attribut.Reftablesid === "ref_desa") {
      const arrdataPilihans = [];
      dataWilReferences[attribut.Reftablesid]?.forEach((item) => {
        if (parseInt(item.IdParent) === parseInt(formSurvey?.dynamicKecamatan))
          arrdataPilihans.push({
            ...item,
            value: item.Kode,
            label: item.Nama,
          });
      });
      setDataPilihans(arrdataPilihans);
    }
    if (attribut.Reftablesid === "ref_rw") {
      const arrdataPilihans = [];
      dataWilReferences[attribut.Reftablesid]?.forEach((item) => {
        if (parseInt(item.IdParent) === parseInt(formSurvey?.dynamicDesa))
          arrdataPilihans.push({
            ...item,
            value: item.Kode,
            label: item.Nama,
          });
      });
      setDataPilihans(arrdataPilihans);
    }
    if (attribut.Reftablesid === "ref_rt") {
      const arrdataPilihans = [];
      dataWilReferences[attribut.Reftablesid]?.forEach((item) => {
        if (parseInt(item.IdParent) === parseInt(formSurvey?.dynamicRw))
          arrdataPilihans.push({
            ...item,
            value: item.Kode,
            label: item.Nama,
          });
      });
      setDataPilihans(arrdataPilihans);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSurvey]);

  const onWil = (val) => {
    val = val[0];
    if (attribut.Reftablesid === "ref_kecamatan")
      dispatch(actFormSurvey("dynamicKecamatan", val?.Kode));
    if (attribut.Reftablesid === "ref_desa")
      dispatch(actFormSurvey("dynamicDesa", val?.Kode));
    if (attribut.Reftablesid === "ref_rw")
      dispatch(actFormSurvey("dynamicRw", val?.Kode));
    if (attribut.Reftablesid === "ref_rt")
      dispatch(actFormSurvey("dynamicRt", val?.Kode));
  };

  const TextField = () => {
    const renderComboTableWilField = () => {
      return (
        <Controller
          control={control}
          name={`Atributs[${index}].StringValue`}
          render={({ field }) => (
            <Combobox
              label={tipeComboWilDynamic[attribut.RefTables.Name]}
              options={dataPilihans}
              values={dataPilihans.filter(
                (x) =>
                  x.value == getValue.Atributs[index].StringValue?.[0]?.value
              )}
              onChange={(val) => {
                field.onChange(val);
                onWil(val);
              }}
              disabled={true}
            />
          )}
          rules={{
            required: false,
          }}
        />
      );
    };
    const renderComboTableField = () => {
      let dataCombos = [];
      attribut.RefTables.Info.forEach((item) => {
        dataCombos.push({
          label: item.Name,
          value: item.Code,
        });
      });
      return (
        <Controller
          control={control}
          name={`Atributs[${index}].StringValue`}
          render={({ field }) => (
            <Combobox
              label={attribut.Alias}
              options={dataCombos}
              values={dataCombos.filter(
                (x) =>
                  x.value == getValue.Atributs[index].StringValue?.[0]?.value
              )}
              onChange={(val) => {
                field.onChange(val);
              }}
              disabled={true}
            />
          )}
          rules={{
            required: false,
          }}
        />
      );
    };
    const renderNumberField = () => {
      return (
        <TextInput
          type="numeric"
          label={attribut.Alias?.toUpperCase()}
          hook={register(`Atributs[${index}].NumberValue`, {
            required: false,
          })}
          disabled={true}
          isError={errors?.Atributs?.[index]?.NumberValue}
        />
      );
    };
    const renderStringField = () => {
      if (attribut.Length < 200)
        return (
          <TextInput
            type="text"
            label={attribut.Alias?.toUpperCase()}
            hook={register(`Atributs[${index}].StringValue`, {
              required: false,
            })}
            disabled={true}
            isError={errors?.Atributs?.[index]?.StringValue}
          />
        );
      return (
        <TextArea
          label={attribut.Alias?.toUpperCase()}
          hook={register(`Atributs[${index}].StringValue`, {
            required: false,
          })}
          disabled={true}
          isError={errors?.Atributs?.[index]?.StringValue}
        />
      );
    };

    if (attribut.RefTables?.Istable === true) {
      return renderComboTableWilField();
    }
    if (attribut.RefTables?.Istable === false) {
      return renderComboTableField();
    }
    if (
      attribut.Type === tipeInputSurvey.double ||
      attribut.Type === tipeInputSurvey.integer
    ) {
      return renderNumberField();
    }
    if (attribut.Type === tipeInputSurvey.string) {
      return renderStringField();
    }

    return <></>;
  };
  return <div className="col-md-12">{TextField()}</div>;
};
const ContainerDynamicFormUpload = ({ data, hookForm }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>NAMA</th>
                <th>GAMBAR</th>
                <th>CATATAN</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => {
                return (
                  <SurveyFormUpload
                    key={index}
                    index={index}
                    attribut={item}
                    hookForm={hookForm}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const SurveyFormUpload = ({ index, attribut }) => {
  return (
    <tr>
      <td>{++index}</td>
      <td>{attribut.JenisFile.Nama}</td>
      <td>
        <a href={attribut.Nama} target="_blank" rel="noreferrer">
          <img
            src={attribut.Nama}
            className="rounded user-photo"
            alt="img-survey"
            style={{ objectFit: "cover", width: 50, height: 50 }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = IMGUserEmpty;
            }}
          />
        </a>
      </td>
      <td>{attribut.Catatan || "tidak ada"}</td>
    </tr>
  );
};

export default SectionView;
