import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { TextInput } from "src/components/atoms";
import { actUserChangePassword } from "src/redux/actions";

const SectionForm = () => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const btnSave = (data) => dispatch(actUserChangePassword(data));

  return (
    <div className="row clearfix">
      <div className="col-lg-6 col-md-6 mx-auto">
        <div className="card planned_task">
          <div className="header">
            <h2>Form Ubah Password</h2>
          </div>
          <div className="body">
            <form onSubmit={handleSubmit(btnSave)}>
              <div className="row">
                <div className="col-md-12">
                  <TextInput
                    type="password"
                    label={"Password Lama"}
                    hook={register("OldPassword", { required: true })}
                    isError={errors.OldPassword}
                  />
                  <TextInput
                    type="password"
                    label={"Password Baru"}
                    hook={register("NewPassword", { required: true })}
                    isError={errors.NewPassword}
                  />
                  <TextInput
                    type="password"
                    label={"Konfirmasi Password Baru"}
                    hook={register("ConfirmPassword", { required: true })}
                    isError={errors.ConfirmPassword}
                  />
                </div>
                <div className="col-md-12 d-flex justify-content-end mt-5">
                  <button
                    type="button"
                    className="btn btn-dark mr-2"
                    onClick={() => reset()}
                  >
                    <i className="fa fa-times"></i> <span>Batalkan</span>
                  </button>
                  <button type="submit" className="btn btn-success">
                    <i className="fa fa-save"></i> <span>Simpan</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionForm;
