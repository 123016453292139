import { useEffect, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";
import { Combobox, TextInput } from "src/components/atoms";
import { useParams } from "react-router-dom";
import { tipeInputKuisioner } from "src/utils";

const SectionAttribute = ({ hook }) => {
  let { customid } = useParams();
  const {
    dataSdiAttrTemplateSurvey,
    dataSdiAttrTemplateKuisioner,
    dataSdiFieldTypes,
    dataSdiPublicationDatasetTypes,
  } = useSelector((state) => state.sdiReducer);
  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = hook;

  const { fields, append, update } = useFieldArray({
    control,
    name: "attributes",
  });

  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    let datas = [];
    if (customid === "kuisioner") {
      dataSdiAttrTemplateKuisioner?.forEach((item, index) => {
        // mapping
        let type = "string",
          length = 225;
        if (item?.Tipejawaban?.Id === tipeInputKuisioner.isian_bilangan) {
          type = "integer";
          length = 11;
        }
        let name =
          item.Kode?.toLowerCase()
            ?.replace(/[^\w\s]/gi, " ")
            ?.replace(/\s+/g, "_") +
          "_" +
          item.Alias?.toLowerCase()
            ?.replace(/\s+$/g, "")
            ?.replace(/[^\w\s]/gi, " ")
            ?.replace(/\s+/g, "_");

        datas.push({
          Atribut: {
            Alias: item.Alias,
            Id: name,
            Indexno: index,
            Length: length,
            Name: name,
            RefTables: null,
            Reftablesid: null,
            Type: type,
          },
        });
      });

      datas = [...jsonAttrQst, ...datas];
    }
    if (customid === "survey")
      datas = [...jsonAttrSrv, ...dataSdiAttrTemplateSurvey];

    if (fields.length === 0)
      datas.forEach((item, index) => {
        let fieldType = item.Atribut.Type;
        let fieldLength = item.Atribut.Length;

        // maping
        if (item.Atribut.Reftablesid !== null) {
          fieldType = "string";
          fieldLength = 100;
        }
        if (fieldType === "double") {
          fieldType = "decimal";
          fieldLength = 15;
        }
        append({
          title: item.Atribut.Name,
          fieldTypeId: dataSdiFieldTypes.filter((x) => x.label === fieldType),
          fieldLength: fieldLength,
          description: item.Atribut.Alias,
          publicationType:
            getValues(`attributes[${index}].publicationType`) || [],
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSdiAttrTemplateSurvey, dataSdiAttrTemplateKuisioner, customid]);

  const onPublicationTypeMassal = () => {
    setIsLoad(true);
    let valOld = fields;
    setValue("attributes", []);

    let publicationType = [
      {
        label: "Terbuka",
        value: 0,
      },
    ];

    setTimeout(() => {
      valOld.forEach((item, index) => {
        update(index, {
          ...item,
          publicationType,
        });
      });
      setIsLoad(false);
    }, 1000);
  };

  return (
    <div className="row clearfix">
      <div className="col-lg-10 col-md-10 mx-auto">
        <div className="card planned_task">
          <div className="header">
            <h2>Form Attribut</h2>
          </div>
          <div className="body">
            {fields.length > 20 && (
              <div className="alert alert-warning">
                Terdapat <b>20+ attribut</b>, apabila anda ingin memilih tipe
                publikasi secara massal klik disini{" "}
                <span
                  className="badge badge-danger"
                  onClick={() => onPublicationTypeMassal()}
                >
                  Set Massal
                </span>
              </div>
            )}
            <form>
              <div className="body table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Nama</th>
                      <th style={{ width: 150 }}>Tipe</th>
                      <th style={{ width: 50 }}>Panjang</th>
                      <th>Publikasi</th>
                      <th>Deksripsi</th>
                    </tr>
                  </thead>

                  <tbody>
                    {fields.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <TextInput
                              label={"Nama"}
                              hideLabel
                              hook={register(`attributes[${index}].title`, {
                                required: true,
                              })}
                              readOnly={true}
                              isError={errors?.attributes?.[index]?.title}
                            />
                          </td>
                          <td>
                            <Controller
                              control={control}
                              name={`attributes[${index}].fieldTypeId`}
                              render={({ field }) => (
                                <Combobox
                                  label={"Tipe"}
                                  hideLabel
                                  options={dataSdiFieldTypes}
                                  values={field.value}
                                  disabled
                                  onChange={(val) => {
                                    field.onChange(val);
                                  }}
                                  isError={
                                    errors?.attributes?.[index]?.fieldTypeId
                                  }
                                />
                              )}
                              rules={{
                                required: true,
                              }}
                            />
                          </td>
                          <td>
                            <TextInput
                              label={"Panjang"}
                              hideLabel
                              hook={register(
                                `attributes[${index}].fieldLength`,
                                {
                                  required: true,
                                }
                              )}
                              readOnly={true}
                              isError={errors?.attributes?.[index]?.fieldLength}
                            />
                          </td>
                          <td>
                            <Controller
                              control={control}
                              name={`attributes[${index}].publicationType`}
                              defaultValue={
                                getValues(
                                  `attributes[${index}].publicationType`
                                ) || []
                              }
                              render={({ field }) => {
                                return (
                                  <Combobox
                                    label={"Publikasi"}
                                    hideLabel
                                    options={dataSdiPublicationDatasetTypes}
                                    onChange={(val) => {
                                      field.onChange(val);
                                    }}
                                    values={field.value}
                                    isError={
                                      errors?.attributes?.[index]
                                        ?.publicationType
                                    }
                                  />
                                );
                              }}
                              rules={{
                                required: true,
                              }}
                            />
                          </td>
                          <td>
                            <TextInput
                              label={"Deskripsi"}
                              hideLabel
                              hook={register(
                                `attributes[${index}].description`,
                                {
                                  required: false,
                                }
                              )}
                              readOnly={true}
                              isError={errors?.attributes?.[index]?.description}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {isLoad && (
                  <center>
                    <div
                      className="spinner-border text-primary m-5"
                      role="status"
                    >
                      <span className="visually-hidden" />
                    </div>
                  </center>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const jsonAttrSrv = [
  {
    Atribut: {
      Alias: "Kode Data",
      Id: "kode_data",
      Indexno: 1,
      Length: 36,
      Name: "kode_data",
      RefTables: null,
      Reftablesid: null,
      Type: "string",
    },
  },
  {
    Atribut: {
      Alias: "Latitude",
      Id: "latitude",
      Indexno: 2,
      Length: 15,
      Name: "latitude",
      RefTables: null,
      Reftablesid: null,
      Type: "double",
    },
  },
  {
    Atribut: {
      Alias: "Longitude",
      Id: "longitude",
      Indexno: 3,
      Length: 15,
      Name: "longitude",
      RefTables: null,
      Reftablesid: null,
      Type: "double",
    },
  },
];

const jsonAttrQst = [
  {
    Atribut: {
      Alias: "Kode Data",
      Id: "kode_data",
      Indexno: 1,
      Length: 36,
      Name: "kode_data",
      RefTables: null,
      Reftablesid: null,
      Type: "string",
    },
  },
  {
    Atribut: {
      Alias: "Nama",
      Id: "nama",
      Indexno: 2,
      Length: 225,
      Name: "nama",
      RefTables: null,
      Reftablesid: null,
      Type: "string",
    },
  },
  {
    Atribut: {
      Alias: "Alamat",
      Id: "alamat",
      Indexno: 3,
      Length: 500,
      Name: "alamat",
      RefTables: null,
      Reftablesid: null,
      Type: "text",
    },
  },
  {
    Atribut: {
      Alias: "No Telpon",
      Id: "no_telpon",
      Indexno: 4,
      Length: 15,
      Name: "no_telpon",
      RefTables: null,
      Reftablesid: null,
      Type: "string",
    },
  },
  {
    Atribut: {
      Alias: "No Handphone",
      Id: "no_handphone",
      Indexno: 4,
      Length: 15,
      Name: "no_handphone",
      RefTables: null,
      Reftablesid: null,
      Type: "string",
    },
  },
  {
    Atribut: {
      Alias: "Email",
      Id: "email",
      Indexno: 5,
      Length: 50,
      Name: "email",
      RefTables: null,
      Reftablesid: null,
      Type: "string",
    },
  },
  {
    Atribut: {
      Alias: "Latitude",
      Id: "latitude",
      Indexno: 6,
      Length: 15,
      Name: "latitude",
      RefTables: null,
      Reftablesid: null,
      Type: "double",
    },
  },
  {
    Atribut: {
      Alias: "Longitude",
      Id: "longitude",
      Indexno: 7,
      Length: 15,
      Name: "longitude",
      RefTables: null,
      Reftablesid: null,
      Type: "double",
    },
  },
];

export default SectionAttribute;
