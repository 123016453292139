import { SurveyService } from "src/services";
import {
  FUNCArraySelectId,
  FUNCArraySelectKode,
  FUNCDateToString,
  FUNCHandleRequest,
  loadingBar,
} from "src/utils";
import Swal from "sweetalert2/dist/sweetalert2";

import { actGetMenus } from "./userAction";

// SECTION REFERENSI SURVEY
export const actGetRefJenisSurveys = () => {
  return (dispatch) => {
    loadingBar(true);
    FUNCHandleRequest(() =>
      SurveyService.getRefJenisSurveys().then((res) => {
        loadingBar(false);
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_REF_JENIS_SURVEYS",
            payload: FUNCArraySelectKode(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};
export const actGetJenisSurveys = () => {
  return (dispatch) => {
    loadingBar(true);
    FUNCHandleRequest(() =>
      SurveyService.getJenisSurveys().then((res) => {
        loadingBar(false);
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_JENIS_SURVEYS",
            payload: data.Data,
          });

          var array = [];
          data.Data.map((v, i) =>
            array.push({
              value: v.Id,
              label: v.Name || v.Nama,
              ...v,
            })
          );
          dispatch({
            type: "DATA_SDI_JENIS_SURVEYS",
            payload: array,
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetJenisSurveyById = (id, history = null) => {
  return (dispatch) => {
    loadingBar(true);
    FUNCHandleRequest(() =>
      SurveyService.getJenisSurvey(id).then((res) => {
        loadingBar(false);
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_JENIS_SURVEY_BY_ID", payload: data.Data });
          if (history) history.push(`/surveys/edit/${id}`);
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetJenisFileSurveys = (jenisSurveyId) => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      SurveyService.getJenisFileSurveys(jenisSurveyId).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_JENIS_FILE_SURVEYS",
            payload: FUNCArraySelectId(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actAddJenisSurvey = (kode) => {
  return (dispatch) => {
    var fd = new FormData();
    Swal.fire({
      title: "Tambah Survey",
      text: `Anda akan membuat survey, dengan Kode survey: ${kode}`,
      inputLabel: "Masukan tahun:",
      input: "number",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Buat Survey!",
      showLoaderOnConfirm: true,
      preConfirm: async (tahun) => {
        try {
          fd.append("tahun", tahun);
        } catch (error) {
          Swal.showValidationMessage(`
            Request failed: ${error}
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        fd.append("refJenissurveyKode", kode);
        FUNCHandleRequest(() =>
          SurveyService.addJenisSurvey(fd).then((res) => {
            var data = res.data;
            if (data?.IsSuccess) {
              Swal.fire("Berhasil", "Survey berhasil dibuat!", "success").then(
                () => {
                  dispatch(actGetJenisSurveys());
                  dispatch(actGetMenus());
                }
              );
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          })
        );
      }
    });
  };
};

export const actEditJenisSurvey = (iData) => {
  return () => {
    loadingBar(true);
    var fd = new FormData();
    fd.append("id", iData.id);
    fd.append("nama", iData.nama);
    fd.append("namapendek", iData.namapendek);
    fd.append("deskripsi", iData.deskripsi);
    fd.append("tanggalmulai", FUNCDateToString(iData.tanggalmulai));
    fd.append("tanggalselesai", FUNCDateToString(iData.tanggalselesai));

    FUNCHandleRequest(() =>
      SurveyService.editJenisSurvey(fd).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          Swal.fire("Berhasil", "Survey berhasil diperbarui!", "success").then(
            () => (window.location = "/surveys")
          );
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actSetAktifSurvey = (id) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan aktivasi survey ini!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        var fd = new FormData();
        fd.append("id", id);
        FUNCHandleRequest(() =>
          SurveyService.setAktifJenissurvey(fd).then((res) => {
            var data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                "Survey berhasil di aktivasi!",
                "success"
              ).then(() => dispatch(actGetJenisSurveys()));
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          })
        );
      }
    });
  };
};

export const actSetTidakAktifSurvey = (id) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan deaktivasi survey ini!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        var fd = new FormData();
        fd.append("id", id);
        FUNCHandleRequest(() =>
          SurveyService.setTidakAktifJenissurvey(fd).then((res) => {
            var data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                "Survey berhasil di deaktivasi!",
                "success"
              ).then(() => dispatch(actGetJenisSurveys()));
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          })
        );
      }
    });
  };
};
