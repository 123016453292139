const initialState = {
  formKuisioner: {
    // KuisionerId: [],
    KodeData: "",
    Longitude: "",
    Latitude: "",
    Nama: "",
    Alamat: "",
    Email: "",
    Notelpon: "",
    Nohandphone: "",
    Catatan: "",

    dynamicProvinsi: process.env.REACT_APP_KODE_PROV,
    dynamicKabkot: process.env.REACT_APP_KODE_KABKOT,
  },
  dataKuisionerTemplate: null,
  dataKuisioners: [],
  dataKuisionerById: null,
  dataJenisFiles: [],
  dataRespondens: null,
  dataRespondenById: null,
};

const kuisionerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FORM_KUISIONER":
      return {
        ...state,
        formKuisioner: {
          ...state.formKuisioner,
          [action.formType]: action.formValue,
        },
      };

    case "RESET_FORM_KUISIONER":
      return { ...state, formKuisioner: initialState.formKuisioner };
    case "DATA_KUISIONER_TEMPLATE":
      return { ...state, dataKuisionerTemplate: action.payload };
    case "DATA_KUISIONERS":
      return { ...state, dataKuisioners: action.payload };
    case "DATA_KUISIONER_BY_ID":
      return { ...state, dataKuisionerById: action.payload };
    case "DATA_JENIS_FILES":
      return { ...state, dataJenisFiles: action.payload };
    case "DATA_RESPONDENS":
      return { ...state, dataRespondens: action.payload };
    case "DATA_RESPONDEN_BY_ID":
      return { ...state, dataRespondenById: action.payload };

    default:
      return state;
  }
};

export default kuisionerReducer;
