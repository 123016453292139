import React, { useEffect } from "react";
import { LayoutClient } from "src/components/molecules";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SectionTable from "./parts/SectionTable";
import { actGetKuisionerById, actGetWilReferences } from "src/redux/actions";
import { useForm } from "react-hook-form";
import { FUNCDateDmytoYmd } from "src/utils";

const KuisionerList = () => {
  let { customid } = useParams();
  const jenisKuisionerId = customid;

  const { dataKuisionerById } = useSelector((state) => state.kuisionerReducer);
  const dispatch = useDispatch();

  const hookForm = useForm();
  const { setValue } = hookForm;

  useEffect(() => {
    dispatch(actGetKuisionerById(jenisKuisionerId));
    dispatch(actGetWilReferences());
  }, [dispatch, jenisKuisionerId]);

  useEffect(() => {
    if (dataKuisionerById) {
      setValue(
        "minDate",
        new Date(FUNCDateDmytoYmd(dataKuisionerById?.Tanggalmulai))
      );
      setValue(
        "maxDate",
        new Date(FUNCDateDmytoYmd(dataKuisionerById?.Tanggalselesai))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataKuisionerById]);

  // let isDisabledByYear = dataKuisionerById?.Tahun !== new Date().getFullYear();
  let isDisabledByYear = dataKuisionerById?.Tahun > new Date().getFullYear();

  return (
    <>
      <LayoutClient
        page={`Data ${dataKuisionerById?.NamaPendek}`}
        breadcrumb={[{ name: dataKuisionerById?.NamaPendek, navigate: "" }]}
      >
        <div className="alert alert-primary">
          <b>{dataKuisionerById?.Nama}</b>
          <p className="mb-0">{dataKuisionerById?.Deskripsi}</p>
        </div>
        {isDisabledByYear && (
          <div className="alert alert-danger">
            <b>Diarsipkan!</b>
            <p className="mb-0">
              Data telah melewati batas tahun yang ditentukan, yaitu tahun{" "}
              <u>{dataKuisionerById?.Tahun}</u>. Oleh karena itu, data tersebut
              tidak dapat diubah lagi. Namun, Anda masih dapat mengunduh file
              data tersebut.
            </p>
          </div>
        )}

        <SectionTable isDisabledByYear={isDisabledByYear} />
      </LayoutClient>
    </>
  );
};

export default KuisionerList;
