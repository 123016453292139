const initialState = {
  dataWilProvinsis: [],
  dataWilKabupatens: [],
  dataWilKecamatans: [],
  dataWilDesas: [],
  dataWilRws: [],
  dataWilRts: [],
  dataWilReferences: [],
};

const wilReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_WIL_PROVINSIS":
      return { ...state, dataWilProvinsis: action.payload };
    case "DATA_WIL_KABUPATENS":
      return { ...state, dataWilKabupatens: action.payload };
    case "DATA_WIL_KECAMATANS":
      return { ...state, dataWilKecamatans: action.payload };
    case "DATA_WIL_DESAS":
      return { ...state, dataWilDesas: action.payload };
    case "DATA_WIL_RWS":
      return { ...state, dataWilRws: action.payload };
    case "DATA_WIL_RTS":
      return { ...state, dataWilRts: action.payload };
    case "DATA_WIL_REFERENCES":
      return { ...state, dataWilReferences: action.payload };
    default:
      return state;
  }
};

export default wilReducer;
