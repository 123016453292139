import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TextArea, TextInput, TextUpload } from "src/components/atoms";
import {
  actUserEditProfile,
  actUserGetJenisFiles,
  actUserInfo,
} from "src/redux/actions";
import {
  FUNCValidateUploadFileExtension,
  FUNCValidateUploadFileSize,
} from "src/utils";

const SectionForm = () => {
  let history = useHistory();

  const { dataUserJenisFiles, dataInfoUser } = useSelector(
    (state) => state.userReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actUserInfo());
    dispatch(actUserGetJenisFiles());
  }, [dispatch]);

  useEffect(() => {
    if (dataInfoUser) {
      setValue("username", dataInfoUser?.Username);
      setValue("email", dataInfoUser?.Email);
      setValue("firstName", dataInfoUser?.FirstName);
      setValue("middleName", dataInfoUser?.MiddleName);
      setValue("lastName", dataInfoUser?.LastName);
      setValue("address", dataInfoUser?.Address);
      setValue("phoneNumber", dataInfoUser?.PhoneNumber);
      setValue("mobileNumber", dataInfoUser?.MobileNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInfoUser]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm();

  const onFile = (val, item, index) => {
    FUNCValidateUploadFileSize(val.target, 2048, "2MB");
    FUNCValidateUploadFileExtension(val.target, ["pdf", "png", "jpg", "jpeg"]);
    let file = {
      type: item.value,
      title: item.label,
      fileUpload: val.target.files[0],
    };
    setValue(`files[${index}]`, file, { shouldValidate: true });
  };
  const btnSave = (data) => dispatch(actUserEditProfile(data));
  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12">
        <div className="card planned_task">
          <div className="header">
            <h2>Form Ubah Profil</h2>
          </div>
          <div className="body">
            <form onSubmit={handleSubmit(btnSave)}>
              <div className="row">
                <div className="col-md-6">
                  <TextInput
                    label={"Username"}
                    hook={register("username", { required: true })}
                    isError={errors.username}
                    disabled={true}
                  />
                  <TextInput
                    type="email"
                    label={"Email"}
                    hook={register("email", { required: true })}
                    isError={errors.email}
                  />
                  <div className="row">
                    <div className="col-md-4">
                      <TextInput
                        label={"Nama Depan"}
                        hook={register("firstName", { required: true })}
                        isError={errors.firstName}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextInput
                        label={"Nama Tengah"}
                        hook={register("middleName", { required: false })}
                        isError={errors.middleName}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextInput
                        label={"Nama Belakang"}
                        hook={register("lastName", { required: false })}
                        isError={errors.lastName}
                      />
                    </div>
                  </div>
                  <TextArea
                    label={"Alamat Tinggal"}
                    hook={register("address", { required: true })}
                    isError={errors.address}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    type="number"
                    label={"Nomor Telepon"}
                    hook={register("phoneNumber", { required: false })}
                    isError={errors.phoneNumber}
                  />
                  <TextInput
                    type="number"
                    label={"Nomor Handphone"}
                    hook={register("mobileNumber", { required: false })}
                    isError={errors.mobileNumber}
                  />
                  {dataUserJenisFiles?.map((item, index) => {
                    var fileOld = dataInfoUser?.Files?.filter(
                      (x) => x.Type === item.value
                    )[0];
                    return (
                      <Controller
                        key={index}
                        control={control}
                        name={`files[${index}]`}
                        render={() => (
                          <>
                            <TextUpload
                              label={item.label}
                              name={`files[${index}]`}
                              fileSelect={
                                getValues(`files[${index}]`)?.fileUpload?.name
                              }
                              onChange={(val) => {
                                onFile(val, item, index);
                              }}
                              onDel={() => setValue(`files[${index}]`, null)}
                              isError={errors.files?.[index]}
                              fileOld={
                                fileOld &&
                                `${fileOld?.UrlPath}/${fileOld?.Name}`
                              }
                            />
                          </>
                        )}
                        rules={{
                          required: !fileOld,
                        }}
                      />
                    );
                  })}
                </div>

                <div className="col-md-12 d-flex justify-content-end mt-5">
                  <button
                    type="button"
                    className="btn btn-dark mr-2"
                    onClick={() => history.goBack()}
                  >
                    <i className="fa fa-times"></i> <span>Batalkan</span>
                  </button>
                  <button type="submit" className="btn btn-success">
                    <i className="fa fa-save"></i> <span>Simpan</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionForm;
